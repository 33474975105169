import { useQuery } from '@tanstack/react-query'

import { getCustomer } from 'src/lib/sante-api/customers'

export const FETCH_CUSTOMERS_QUERY_KEY = 'customers'

/**
 * @param {number|string|null|undefined} id
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useGetCustomerByIdQuery(id) {
  id = typeof id === 'string' ? parseInt(id) : id

  return useQuery({
    enabled: !!id,
    queryFn: () => getCustomer(id),
    queryKey: [FETCH_CUSTOMERS_QUERY_KEY, id],
  })
}
