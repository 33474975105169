import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash-es'

/**
 * @typedef LayoutState
 * @type {Object}
 * @property {boolean} sidebarShow
 * @property {boolean} sidebarUnfoldable
 */

/** @type {LayoutState} */
const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

export const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setState: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = get(action.payload, key, state[key])
      })
    },
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable = action.payload
    },
  },
})

export const { setState, setSidebarShow, setSidebarUnfoldable } = slice.actions

export default slice.reducer
