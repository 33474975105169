import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import {
  changePassword,
  changeUserStatus,
  destroyUser,
  fetchUsers,
  resetUserPermissions,
  showUser,
  storeUser,
  updateUser,
} from './actions'

const adapter = createEntityAdapter()

const initialState = adapter.getInitialState({
  currentData: null,

  modalState: { action: '', isOpen: false },

  requests: {},
})

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentData = action.payload
    },
    setOpenModal: (state, action) => {
      state.modalState = action.payload ?? initialState.modalState
    },
    closeModal: (state) => {
      state.modalState = initialState.modalState
    },
    openModalToCreateUser: (state) => {
      state.modalState = { isOpen: true, action: 'create' }
    },
    confirmUserDeletion: (state, action) => {
      state.currentData = action.payload
      state.modalState = { isOpen: true, action: 'delete' }
    },
    cancelUserDeletion: (state, action) => {
      state.currentData = initialState.currentData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        setRequest(state, { req: 'fetchUsers', status: 'pending' })
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload)
        setRequest(state, { req: 'fetchUsers', status: 'fulfilled' })
      })
      .addCase(fetchUsers.rejected, (state) => {
        setRequest(state, { req: 'fetchUsers', status: 'rejected' })
      })
      .addCase(storeUser.pending, (state) => {
        setRequest(state, { req: 'storeUser', status: 'pending' })
      })
      .addCase(storeUser.fulfilled, (state, action) => {
        adapter.addOne(state, action.payload)
        state.modalState = initialState.modalState
        setRequest(state, { req: 'storeUser', status: 'fulfilled' })
      })
      .addCase(storeUser.rejected, (state) => {
        state.modalState = initialState.modalState
        setRequest(state, { req: 'storeUser', status: 'rejected' })
      })
      .addCase(showUser.pending, (state) => {
        setRequest(state, { req: 'showUser', status: 'pending' })
      })
      .addCase(showUser.fulfilled, (state, action) => {
        state.currentData = action.payload
        setRequest(state, { req: 'showUser', status: 'fulfilled' })
      })
      .addCase(showUser.rejected, (state) => {
        setRequest(state, { req: 'showUser', status: 'rejected' })
      })
      .addCase(updateUser.pending, (state) => {
        setRequest(state, 'updateUser', true, 'pending', 'Salvando alterações')
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.modalState = initialState.modalState
        state.currentData = initialState.currentData
        adapter.updateOne(state, action.payload)
        setRequest(state, { req: 'updateUser', status: 'fulfilled' })
      })
      .addCase(updateUser.rejected, (state) => {
        setRequest(state, { req: 'updateUser', status: 'rejected' })
      })
      .addCase(destroyUser.pending, (state) => {
        setRequest(state, { req: 'destroyUser', status: 'pending' })
      })
      .addCase(destroyUser.fulfilled, (state, action) => {
        state.modalState = initialState.modalState
        adapter.removeOne(state, action.payload)
        setRequest(state, { req: 'destroyUser', status: 'fulfilled' })
      })
      .addCase(destroyUser.rejected, (state) => {
        state.modalState = initialState.modalState
        setRequest(state, { req: 'destroyUser', status: 'rejected' })
      })
      .addCase(changeUserStatus.pending, (state, action) => {
        setRequest(state, { req: 'changeUserStatus', status: 'pending', meta: action.meta.arg })
      })
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        state.currentData = action.payload
        adapter.updateOne(state, { id: action.payload.id, changes: action.payload })
        setRequest(state, { req: 'changeUserStatus', status: 'fulfilled' })
      })
      .addCase(changeUserStatus.rejected, (state) => {
        setRequest(state, { req: 'changeUserStatus', status: 'rejected' })
      })
      .addCase(changePassword.pending, (state) => {
        setRequest(state, { req: 'changePassword', status: 'pending' })
      })
      .addCase(changePassword.fulfilled, (state) => {
        setRequest(state, { req: 'changePassword', status: 'fulfilled' })
      })
      .addCase(changePassword.rejected, (state) => {
        setRequest(state, { req: 'changePassword', status: 'rejected' })
      })
      .addCase(resetUserPermissions.pending, (state) => {
        setRequest(state, { req: 'resetUserPermissions', status: 'pending' })
      })
      .addCase(resetUserPermissions.fulfilled, (state, action) => {
        state.currentData = action.payload
        adapter.updateOne(state, { id: action.payload.id, changes: action.payload })
        setRequest(state, { req: 'resetUserPermissions', status: 'fulfilled' })
      })
      .addCase(resetUserPermissions.rejected, (state) => {
        setRequest(state, { req: 'resetUserPermissions', status: 'rejected' })
      })
  },
})

export const {
  setOpenModal,
  setUser,
  closeModal,
  openModalToCreateUser,
  confirmUserDeletion,
  cancelUserDeletion,
} = slice.actions

export const userSelectors = adapter.getSelectors((state) => state.users)

export * from './actions'

export default slice.reducer
