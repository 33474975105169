import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

export const handleCreditCardModal = createAction(
  'creditCards/handleCreditCardModal',
  (isOpen = false, action = '', creditCard = null) => ({
    payload: { creditCard, modalState: { action, isOpen } },
  }),
)

export const fetchCreditCards = createAsyncThunk(
  'creditCards/fetch',
  async (customerId) => {
    const res = await santeApi.get(`admin/customers/${customerId}/credit-cards`)
    return { customerId, creditCards: res.data }
  },
  { condition: (customerId, { getState }) => customerId !== getState().creditCards.customerId },
)

export const storeCreditCard = createAsyncThunk(
  'creditCards/store',
  async ({ customerId, data }) => {
    const res = await santeApi.post(`admin/customers/${customerId}/credit-cards`, data)
    return res.data
  },
)

export const updateCreditCard = createAsyncThunk(
  'creditCards/update',
  async ({ customerId, id, data }) => {
    const res = await santeApi.put(`admin/customers/${customerId}/credit-cards/${id}`, data)
    return { id, changes: res.data }
  },
)

export const destroyCreditCard = createAsyncThunk(
  'creditCards/destroy',
  async ({ customerId, id }) => {
    await santeApi.delete(`admin/customers/${customerId}/credit-cards/${id}`)
    return { id }
  },
)
