export const acceptTypes = {
  json: 'application/json',
  pdf: 'application/pdf',
  xlsx: 'application/xlsx',
}

export const acceptOptions = [
  { label: 'PDF', value: acceptTypes.pdf },
  { label: 'Excel', value: acceptTypes.xlsx },
]

/**
 *
 * @param {import('axios').AxiosResponse} res
 * @return {{blob: Blob, filename: string|null}}
 */
export function createBlobFromResponse(res) {
  const blob = new Blob([res.data], { type: res.headers['content-type'] })

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

  const matches = filenameRegex.exec(res.headers['content-disposition'])

  return { blob, filename: matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : null }
}

export function openFileAnotherTab(file) {
  const blobUrl = URL.createObjectURL(file)

  window.open(blobUrl, '_blank')

  setTimeout(() => {
    URL.revokeObjectURL(blobUrl)
  }, 1000 * 60) // 1 minute
}

/**
 *
 * @param {Blob} blob
 * @param {string} defaultName
 */
export function downloadFile(blob, defaultName = 'document.pdf') {
  const link = document.createElement('a')

  link.href = URL.createObjectURL(blob)
  link.download = defaultName
  link.click()

  URL.revokeObjectURL(link.href)
}
