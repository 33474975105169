import { useQuery } from '@tanstack/react-query'

import { getAttendanceSchedules } from 'src/lib/sante-api/attendance-schedules'

export const GET_SCHEDULES_QUERY_KEY = 'get-attendance-schedules'

/**
 * @param {'clinic'|'professional'} entity
 * @param {number=} entityId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useGetSchedulesQuery(entity, entityId, options = {}) {
  return useQuery({
    ...options,
    enabled: !!entityId,
    queryKey: [GET_SCHEDULES_QUERY_KEY, entity, entityId],
    queryFn: () => getAttendanceSchedules(entity, entityId),
  })
}
