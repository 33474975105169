export const CustomerStatus = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  AWAITING_FIRST_PAYMENT: 'awaiting_first_payment',
  NO_ACTIVE_CONTRACT: 'no_active_contract',

  /**
   *
   * @param {string} status
   * @returns {boolean}
   */
  isActive(status) {
    return this.ACTIVE === status
  },

  /**
   *
   * @param {string} status
   * @returns {boolean}
   */
  isSuspended(status) {
    return this.SUSPENDED === status
  },

  /**
   *
   * @param {string} status
   * @returns {boolean}
   */
  isAwaitingPayment(status) {
    return this.AWAITING_FIRST_PAYMENT === status
  },

  /**
   *
   * @param {string} status
   * @returns {boolean}
   */
  isWithoutContract(status) {
    return this.NO_ACTIVE_CONTRACT === status
  },

  /**
   * Get color by status
   *
   * @param {string} status
   * @returns {string}
   */
  color(status) {
    const colors = {
      [this.ACTIVE]: 'success',
      [this.SUSPENDED]: 'danger',
      [this.AWAITING_FIRST_PAYMENT]: 'warning',
      [this.NO_ACTIVE_CONTRACT]: 'warning',
    }

    if (!(status in colors)) return 'default'

    return colors[status]
  },

  /**
   * Get color by status
   *
   * @param {string} status
   * @returns {string}
   */
  display(status) {
    const values = {
      [this.ACTIVE]: 'Ativo',
      [this.SUSPENDED]: 'Suspenso',
      [this.AWAITING_FIRST_PAYMENT]: 'Aguardando o primeiro pagamento',
      [this.NO_ACTIVE_CONTRACT]: 'Sem contrato',
    }

    if (!(status in values)) return '-'

    return values[status]
  },
}
