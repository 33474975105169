import { useQuery } from '@tanstack/react-query'

import { fetchSpecialties } from 'src/lib/sante-api/specialties'

export const FETCH_SPECIALTIES_QUERY_KEY = 'specialties'

/**
 * @param {Object} params
 * @param {number=} params.clc Clinic id
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useFetchSpecialtiesQuery(params = {}, options = {}) {
  const { data: specialties = [], ...rest } = useQuery({
    ...options,
    queryKey: [FETCH_SPECIALTIES_QUERY_KEY, params],
    queryFn: () => fetchSpecialties(params),
  })

  return { specialties, ...rest }
}
