import { createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import * as api from './actions'

import { setUserCallback } from './helper'

const initialState = {
  action: '',

  user: null,

  role: null,
  permissions: [],

  isAuthenticated: false,
  isCustomer: false,
  rolePermissionsSynced: false,

  requests: {},
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state = setUserCallback(state, action)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(api.signOut, (state) => {
        state.isAuthenticated = false
        state = initialState
      })
      // Asynchronous actions
      .addCase(api.signIn.pending, (state) => {
        setRequest(state, { req: 'signIn', status: 'pending' })
      })
      .addCase(api.signIn.fulfilled, (state, action) => {
        state = setUserCallback(state, action)
        setRequest(state, { req: 'signIn', status: 'fulfilled' })
      })
      .addCase(api.signIn.rejected, (state) => {
        setRequest(state, { req: 'signIn', status: 'rejected' })
      })
      .addCase(api.changePassword.pending, (state) => {
        setRequest(state, { req: 'changePassword', status: 'pending' })
      })
      .addCase(api.changePassword.fulfilled, (state) => {
        setRequest(state, { req: 'changePassword', status: 'fulfilled' })
      })
      .addCase(api.changePassword.rejected, (state) => {
        setRequest(state, { req: 'changePassword', status: 'rejected' })
      })
      .addCase(api.forgotPassword.pending, (state) => {
        setRequest(state, { req: 'forgotPassword', status: 'pending' })
      })
      .addCase(api.forgotPassword.fulfilled, (state, { payload: { message } }) => {
        setRequest(state, { req: 'forgotPassword', status: 'fulfilled', message })
      })
      .addCase(api.forgotPassword.rejected, (state, { payload: { message } }) => {
        setRequest(state, { req: 'forgotPassword', status: 'rejected', message })
      })
      .addCase(api.resetPassword.pending, (state) => {
        setRequest(state, { req: 'resetPassword', status: 'pending' })
      })
      .addCase(api.resetPassword.fulfilled, (state, { payload: { message } }) => {
        setRequest(state, { req: 'resetPassword', status: 'fulfilled', message })
      })
      .addCase(api.resetPassword.rejected, (state, { payload: { message } }) => {
        setRequest(state, { req: 'resetPassword', status: 'rejected', message })
      })
      .addCase(api.sendEmailVerificationLink.pending, (state) => {
        setRequest(state, { req: 'sendEmailVerificationLink', status: 'pending' })
      })
      .addCase(api.sendEmailVerificationLink.fulfilled, (state, { payload: { message, user } }) => {
        if (user) state.user = user
        setRequest(state, { req: 'sendEmailVerificationLink', status: 'fulfilled', message })
      })
      .addCase(api.sendEmailVerificationLink.rejected, (state, { payload: { message } }) => {
        setRequest(state, { req: 'sendEmailVerificationLink', status: 'rejected', message })
      })
      .addCase(api.verifyEmail.pending, (state) => {
        setRequest(state, { req: 'verifyEmail', status: 'pending' })
      })
      .addCase(api.verifyEmail.fulfilled, (state, { payload: { message, user } }) => {
        if (user) state.user = user
        setRequest(state, { req: 'verifyEmail', status: 'fulfilled', message })
      })
      .addCase(api.verifyEmail.rejected, (state, { payload: { message } }) => {
        setRequest(state, { req: 'verifyEmail', status: 'rejected', message })
      })
      .addCase(api.syncRoleAndPermissions.pending, (state) => {
        state.rolePermissionsSynced = false
        setRequest(state, { req: 'syncRoleAndPermissions', status: 'pending' })
      })
      .addCase(api.syncRoleAndPermissions.fulfilled, (state, action) => {
        state.user = action.payload
        state.role = action.payload.role
        state.permissions = action.payload.permissions

        state.rolePermissionsSynced = true
        setRequest(state, { req: 'syncRoleAndPermissions', status: 'fulfilled' })
      })
      .addCase(api.syncRoleAndPermissions.rejected, (state) => {
        setRequest(state, { req: 'syncRoleAndPermissions', status: 'rejected' })
      })
      .addCase(api.updateProfile.pending, (state) => {
        setRequest(state, { req: 'updateProfile', status: 'pending' })
      })
      .addCase(api.updateProfile.fulfilled, (state, action) => {
        state.user = action.payload
        setRequest(state, { req: 'updateProfile', status: 'fulfilled' })
      })
      .addCase(api.updateProfile.rejected, (state) => {
        setRequest(state, { req: 'updateProfile', status: 'rejected' })
      })
  },
})

export const { setUser } = slice.actions

export * from './actions'

export default slice.reducer
