import { useQuery } from '@tanstack/react-query'

import { fetchNeededToAppointment } from 'src/lib/sante-api/appointments'

export const FETCH_NEEDED_DATA_QUERY_KEY = 'appointments-needed-data'

/**
 * Fetch needed data to set-location-and-time step in appointment form
 *
 * @param {number=} attendanceTypeId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useFetchNeededDataQuery(attendanceTypeId, options = {}) {
  return useQuery({
    ...options,
    enabled: !!attendanceTypeId,
    queryKey: [FETCH_NEEDED_DATA_QUERY_KEY, attendanceTypeId],
    queryFn: () => fetchNeededToAppointment(attendanceTypeId),
  })
}
