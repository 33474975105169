import { useQuery } from '@tanstack/react-query'

import { getProfessionalById } from 'src/lib/sante-api/professionals'

import { FETCH_PROFESSIONALS_QUERY_KEY } from './useFetchProfessionalsQuery'

/**
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetProfessionalByIdQuery(id, options = {}) {
  id = typeof id === 'string' ? parseInt(id) : id

  return useQuery({
    ...options,
    queryFn: () => getProfessionalById(id),
    queryKey: [FETCH_PROFESSIONALS_QUERY_KEY, id],
  })
}
