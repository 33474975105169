import { createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import { fetchStatistics } from './actions'

const initialState = {
  appointments: [],

  customers: 0,

  professionals: 0,

  requests: {},
}

export const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatistics.pending, (state) => {
      setRequest(state, { req: 'fetchStatistics', status: 'pending' })
    })
    builder.addCase(fetchStatistics.fulfilled, (state, action) => {
      state.customers = action.payload?.customers ?? initialState.customers
      state.appointments = action.payload?.appointments ?? initialState.appointments
      state.professionals = action.payload?.professionals ?? initialState.professionals
      setRequest(state, { req: 'fetchStatistics', status: 'fulfilled' })
    })
    builder.addCase(fetchStatistics.rejected, (state) => {
      setRequest(state, { req: 'fetchStatistics', status: 'rejected' })
    })
  },
})

// export const {} = slice.actions;

export * from './actions'

export default slice.reducer
