import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import * as api from './actions'

const adapter = createEntityAdapter()

const initialState = {
  currentData: {},

  modalState: { action: '', isOpen: false },

  requests: {},
}

export const slice = createSlice({
  name: 'modalities',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setModality: (state, action) => {
      state.currentData = action.payload
    },
    setOpenModal: (state, action) => {
      state.modalState = action.payload ? action.payload : initialState.modalState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(api.handleModalState, (state, action) => {
        state.modalState = action.payload.modalState
        state.currentData = action.payload.selected
      })
      .addCase(api.fetchModalities.pending, (state) => {
        setRequest(state, { req: 'fetchModalities', status: 'pending' })
      })
      .addCase(api.fetchModalities.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload)
        setRequest(state, { req: 'fetchModalities', status: 'fulfilled' })
      })
      .addCase(api.fetchModalities.rejected, (state) => {
        setRequest(state, { req: 'fetchModalities', status: 'rejected' })
      })
      .addCase(api.storeModality.pending, (state) => {
        setRequest(state, { req: 'storeModality', status: 'pending' })
      })
      .addCase(api.storeModality.fulfilled, (state, action) => {
        adapter.addOne(state, action.payload)
        state.modalState = initialState.modalState
        setRequest(state, { req: 'storeModality', status: 'fulfilled' })
      })
      .addCase(api.storeModality.rejected, (state) => {
        setRequest(state, { req: 'storeModality', status: 'rejected' })
      })
      .addCase(api.getModality.pending, (state) => {
        setRequest(state, { req: 'getModality', status: 'pending' })
      })
      .addCase(api.getModality.fulfilled, (state, action) => {
        state.currentData = action.payload
        setRequest(state, { req: 'getModality', status: 'fulfilled' })
      })
      .addCase(api.getModality.rejected, (state) => {
        setRequest(state, { req: 'getModality', status: 'rejected' })
      })
      .addCase(api.updateModality.pending, (state) => {
        setRequest(state, { req: 'updateModality', status: 'pending' })
      })
      .addCase(api.updateModality.fulfilled, (state, action) => {
        state.modalState = initialState.modalState
        state.currentData = initialState.currentData
        adapter.updateOne(state, action.payload)
        setRequest(state, { req: 'updateModality', status: 'fulfilled' })
      })
      .addCase(api.updateModality.rejected, (state) => {
        setRequest(state, { req: 'updateModality', status: 'rejected' })
      })
      .addCase(api.destroyModality.pending, (state) => {
        setRequest(state, { req: 'destroyModality', status: 'pending' })
      })
      .addCase(api.destroyModality.fulfilled, (state, action) => {
        state.modalState = initialState.modalState
        state.currentData = initialState.currentData
        adapter.removeOne(state, action.payload.id)
        setRequest(state, { req: 'destroyModality', status: 'fulfilled' })
      })
      .addCase(api.destroyModality.rejected, (state) => {
        state.modalState = initialState.modalState
        state.currentData = initialState.currentData
        setRequest(state, { req: 'destroyModality', status: 'rejected' })
      })
  },
})

export const { setModality, setOpenModal } = slice.actions

export const modalitiesSelectors = adapter.getSelectors((state) => state.modalities)

export * from './actions'

export default slice.reducer
