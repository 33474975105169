import santeApi from 'src/services/sante-api'

/**
 * @param {import('src/types/sante-api').FetchServicesParams} params
 * @returns {Promise<import('src/types/sante-api').Service[]>}
 */
export async function fetchServices(params = {}) {
  return (await santeApi.get('admin/services', { params }))?.data
}

/**
 *
 * @param {import('src/types/sante-api').Service} data
 * @returns {Promise<import('src/types/sante-api').Service>}
 */
export async function createService(data) {
  return (await santeApi.post('admin/services', data))?.data
}

/**
 *
 * @param {number} id
 * @param {Partial<import('src/types/sante-api').Service>} data
 * @returns {Promise<import('src/types/sante-api').Service>}
 */
export async function updateService(id, data) {
  return (await santeApi.put(`admin/services/${id}`, data))?.data
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function deleteService(id) {
  await santeApi.delete(`admin/services/${id}`)
}

/**
 *
 * @param {number} id
 * @returns {Promise<import('src/types/sante-api').Service>}
 */
export async function toggleService(id) {
  return (await santeApi.patch(`admin/services/${id}/toggle-active`))?.data
}

/**
 *
 * @param {number} clinicId
 * @param {number[]} services
 * @returns {Promise<void>}
 */
export async function moveServicesToClinic(clinicId, services) {
  await santeApi.post(`admin/clinics/${clinicId}/link-services`, { services })
}
