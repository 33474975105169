import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selected: null,
  dialogState: { action: '', isOpen: false },
}

export const slice = createSlice({
  name: 'professionals',
  initialState,
  reducers: {
    cancelProfessionalDeletion: (state) => {
      state.selected = initialState.selected
      state.dialogState = initialState.dialogState
    },
    confirmProfessionalDeletion: (state, action) => {
      state.selected = action.payload.professional

      state.dialogState = {
        action: action.payload.isForce ? 'forceDelete' : 'delete',
        isOpen: true,
      }
    },
  },
})

export const { cancelProfessionalDeletion, confirmProfessionalDeletion } = slice.actions

export default slice.reducer

export * from './useFetchProfessionalsQuery'
export * from './useGetProfessionalByIdQuery'
export * from './useCreateProfessionalMutation'
export * from './useDeleteProfessionalMutation'
export * from './useRestoreProfessionalMutation'
export * from './useSyncProfessionalServicesMutation'
export * from './useSyncProfessionalSpecialtiesMutation'
export * from './useToggleProfessionalMutation'
export * from './useUpdateProfessionalMutation'
