import { format, parse } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'

export function getLocale() {
  const locales = { en: enUS, 'pt-BR': ptBR }

  return navigator.language in locales ? locales[navigator.language] : enUS
}

export function createDateFromString(str, format = 'yyyy-MM-dd') {
  return parse(str, format, new Date(), { locale: getLocale() })
}

/**
 *
 * @param {Date|string} date
 * @param {string?} formatStr
 * @returns {string}
 */
export function formatDate(date, formatStr = 'yyyy-MM-dd') {
  if (typeof date === 'string') {
    date = new Date(date.length === 10 ? date + 'T00:00:00' : date)
  }
  return format(date, formatStr, { locale: getLocale() })
}

/**
 *
 * @param {string} date
 * @param {'d/m/Y'|'Y-m-d'} format
 * @returns {Boolean}
 */
export function isValidDate(date, format = 'Y-m-d') {
  try {
    let day = ''
    let month = ''
    let year = ''

    if (format.indexOf('Y') === 0) {
      day = date.split('-')[2]
      month = date.split('-')[1]
      year = date.split('-')[0]
    } else {
      day = date.split('-')[0]
      month = date.split('-')[1]
      year = date.split('-')[2]
    }

    if (year.length !== 4) return false

    const value = new Date(`${year}-${month}-${day}`)
    return value instanceof Date && !isNaN(value.valueOf())
  } catch (error) {
    return false
  }
}
