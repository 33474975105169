import { useMutation, useQueryClient } from '@tanstack/react-query'

import { FETCH_SERVICES_QUERY_KEY } from './useFetchServicesQuery'

import { moveServicesToClinic } from 'src/lib/sante-api/services'

/**
 *
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useMoveServicesToClinicMutation(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,

    mutationFn: ({ clinicId, services }) => moveServicesToClinic(clinicId, services),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [FETCH_SERVICES_QUERY_KEY] })
    },
  })
}
