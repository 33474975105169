import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { fetchProfessionals } from 'src/lib/sante-api/professionals'

export const FETCH_PROFESSIONALS_QUERY_KEY = 'professionals'

const SESSION_KEY = `@Sante:RQ:${FETCH_PROFESSIONALS_QUERY_KEY}`

export function setQueryFromSessionStorage(value) {
  sessionStorage.setItem(SESSION_KEY, value)
}

/**
 * @returns {Array<any>}
 */
export function getQueryFromSessionStorage() {
  const rawKey = sessionStorage.getItem(SESSION_KEY)
  return rawKey ? JSON.parse(rawKey) : []
}

/**
 * @param {Object} params
 * @param {number=} params.clc
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useFetchProfessionalsQuery(params = {}, options = {}) {
  const strQueryKey = JSON.stringify([FETCH_PROFESSIONALS_QUERY_KEY, params])

  useEffect(() => {
    setQueryFromSessionStorage(strQueryKey)
  }, [strQueryKey])

  return useQuery({
    ...options,
    queryFn: () => fetchProfessionals(params),
    queryKey: [FETCH_PROFESSIONALS_QUERY_KEY, params],
  })
}
