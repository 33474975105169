import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions } from 'src/features/helpers'

export const storeContact = createAsyncThunk(
  'customers/storeContact',
  async ({ customerId, data }, { getState }) => {
    const res = await santeApi.post(`admin/customers/${customerId}/contacts`, data)

    return actions.addItemToSubList({
      item: res.data,
      subList: 'contacts',
      state: getState().customers,
    })
  },
)

export const updateContact = createAsyncThunk(
  'customers/updateContact',
  async ({ customerId, id, data }, { getState }) => {
    const res = await santeApi.put(`admin/customers/${customerId}/contacts/${id}`, data)

    return actions.updateItemInSubList({
      item: res.data,
      subList: 'contacts',
      state: getState().customers,
    })
  },
)

export const destroyContact = createAsyncThunk(
  'customers/destroyContact',
  async ({ customerId, id }, { getState }) => {
    await santeApi.delete(`admin/customers/${customerId}/contacts/${id}`)

    return actions.removeItemFromSubList({
      itemId: id,
      subList: 'contacts',
      state: getState().customers,
    })
  },
)
