import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions } from 'src/features/helpers'

export const storeAddress = createAsyncThunk(
  'customers/storeAddress',
  async ({ customerId, data }, { getState }) => {
    const res = await santeApi.post(`admin/customers/${customerId}/addresses`, data)

    return actions.addItemToSubList({
      item: res.data,
      subList: 'addresses',
      state: getState().customers,
    })
  },
)

export const updateAddress = createAsyncThunk(
  'customers/updateAddress',
  async ({ customerId, id, data }, { getState }) => {
    const res = await santeApi.put(`admin/customers/${customerId}/addresses/${id}`, data)

    return actions.updateItemInSubList({
      item: res.data,
      subList: 'addresses',
      state: getState().customers,
    })
  },
)

export const destroyAddress = createAsyncThunk(
  'customers/destroyAddress',
  async ({ customerId, id }, { getState }) => {
    await santeApi.delete(`admin/customers/${customerId}/addresses/${id}`)

    return actions.removeItemFromSubList({
      itemId: id,
      subList: 'addresses',
      state: getState().customers,
    })
  },
)
