import { createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import {
  destroyModule,
  destroyPermission,
  destroyRole,
  fetchModules,
  fetchPermissions,
  fetchRoles,
  storeModule,
  storePermission,
  storeRole,
  syncRoleAndPermissionsToUser,
  updateModule,
  updatePermission,
  updateRole,
} from './actions'

const initialState = {
  roles: [],
  role: null,

  permissions: [],
  permission: null,

  modules: [],
  module: null,

  openModal: { action: '', isOpen: false, entity: null },

  requests: {},
}

export const slice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setPermission: (state, action) => {
      state.permission = action.payload
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload
    },
    setPermissionModule: (state, action) => {
      state.module = action.payload
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload ? action.payload : initialState.openModal
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        setRequest(state, { req: 'fetchRoles', status: 'pending' })
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload
        setRequest(state, { req: 'fetchRoles', status: 'fulfilled' })
      })
      .addCase(fetchRoles.rejected, (state) => {
        setRequest(state, { req: 'fetchRoles', status: 'rejected' })
      })
      .addCase(storeRole.pending, (state) => {
        setRequest(state, { req: 'storeRole', status: 'pending' })
      })
      .addCase(storeRole.fulfilled, (state, action) => {
        state.roles = action.payload
        state.role = initialState.role
        state.openModal = initialState.openModal
        setRequest(state, { req: 'storeRole', status: 'fulfilled' })
      })
      .addCase(storeRole.rejected, (state) => {
        setRequest(state, { req: 'storeRole', status: 'rejected' })
      })
      .addCase(updateRole.pending, (state) => {
        setRequest(state, { req: 'updateRole', status: 'pending' })
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.roles = action.payload
        state.role = initialState.role
        state.openModal = initialState.openModal
        setRequest(state, { req: 'updateRole', status: 'fulfilled' })
      })
      .addCase(updateRole.rejected, (state) => {
        setRequest(state, { req: 'updateRole', status: 'rejected' })
      })
      .addCase(destroyRole.pending, (state) => {
        setRequest(state, { req: 'destroyRole', status: 'pending' })
      })
      .addCase(destroyRole.fulfilled, (state, action) => {
        state.roles = action.payload
        state.role = initialState.role
        state.openModal = initialState.openModal
        setRequest(state, { req: 'destroyRole', status: 'fulfilled' })
      })
      .addCase(destroyRole.rejected, (state) => {
        setRequest(state, { req: 'destroyRole', status: 'rejected' })
      })
      .addCase(fetchModules.pending, (state) => {
        setRequest(state, { req: 'fetchModules', status: 'pending' })
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.modules = action.payload
        state.module = action.payload[0]
        setRequest(state, { req: 'fetchModules', status: 'fulfilled' })
      })
      .addCase(fetchModules.rejected, (state) => {
        setRequest(state, { req: 'fetchModules', status: 'rejected' })
      })
      .addCase(storeModule.pending, (state) => {
        setRequest(state, { req: 'storeModule', status: 'pending' })
      })
      .addCase(storeModule.fulfilled, (state, action) => {
        state.modules = action.payload.modules
        state.permissions = action.payload.permissions

        state.openModal = initialState.openModal
        setRequest(state, { req: 'storeModule', status: 'fulfilled' })
      })
      .addCase(storeModule.rejected, (state) => {
        setRequest(state, { req: 'storeModule', status: 'rejected' })
      })
      .addCase(updateModule.pending, (state) => {
        setRequest(state, { req: 'updateModule', status: 'pending' })
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.modules = action.payload.modules
        state.permissions = action.payload.permissions

        state.module = initialState.module
        state.openModal = initialState.openModal
        setRequest(state, { req: 'updateModule', status: 'fulfilled' })
      })
      .addCase(updateModule.rejected, (state) => {
        setRequest(state, { req: 'updateModule', status: 'rejected' })
      })
      .addCase(destroyModule.pending, (state) => {
        setRequest(state, { req: 'destroyModule', status: 'pending' })
      })
      .addCase(destroyModule.fulfilled, (state, action) => {
        state.module = action.payload.module
        state.modules = action.payload.modules
        state.permissions = action.payload.permissions

        state.openModal = initialState.openModal
        setRequest(state, { req: 'destroyModule', status: 'fulfilled' })
      })
      .addCase(destroyModule.rejected, (state) => {
        setRequest(state, { req: 'destroyModule', status: 'rejected' })
      })
      .addCase(fetchPermissions.pending, (state) => {
        setRequest(state, { req: 'fetchPermissions', status: 'pending' })
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload
        setRequest(state, { req: 'fetchPermissions', status: 'fulfilled' })
      })
      .addCase(fetchPermissions.rejected, (state) => {
        setRequest(state, { req: 'fetchPermissions', status: 'rejected' })
      })
      .addCase(storePermission.pending, (state) => {
        setRequest(state, { req: 'storePermission', status: 'pending' })
      })
      .addCase(storePermission.fulfilled, (state, action) => {
        state.permissions = action.payload
        state.openModal = initialState.openModal
        setRequest(state, { req: 'storePermission', status: 'fulfilled' })
      })
      .addCase(storePermission.rejected, (state) => {
        setRequest(state, { req: 'storePermission', status: 'rejected' })
      })
      .addCase(updatePermission.pending, (state) => {
        setRequest(state, { req: 'updatePermission', status: 'pending' })
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        state.permissions = action.payload
        state.openModal = initialState.openModal
        state.permission = initialState.permission
        setRequest(state, { req: 'updatePermission', status: 'fulfilled' })
      })
      .addCase(updatePermission.rejected, (state) => {
        setRequest(state, { req: 'updatePermission', status: 'rejected' })
      })
      .addCase(destroyPermission.pending, (state) => {
        setRequest(state, { req: 'destroyPermission', status: 'pending' })
      })
      .addCase(destroyPermission.fulfilled, (state, action) => {
        state.permissions = action.payload
        state.openModal = initialState.openModal
        setRequest(state, { req: 'destroyPermission', status: 'fulfilled' })
      })
      .addCase(destroyPermission.rejected, (state) => {
        setRequest(state, { req: 'destroyPermission', status: 'rejected' })
      })
      .addCase(syncRoleAndPermissionsToUser.pending, (state) => {
        setRequest(state, { req: 'syncRoleAndPermissionsToUser', status: 'pending' })
      })
      .addCase(syncRoleAndPermissionsToUser.fulfilled, (state, action) => {
        setRequest(state, { req: 'syncRoleAndPermissionsToUser', status: 'fulfilled' })
      })
      .addCase(syncRoleAndPermissionsToUser.rejected, (state) => {
        setRequest(state, { req: 'syncRoleAndPermissionsToUser', status: 'rejected' })
      })
  },
})

export const {
  setOpenModal,
  setPermission,
  setPermissionModule,
  setPermissions,
  setRole,
  setRoles,
} = slice.actions

export * from './actions'

export default slice.reducer
