import { createSlice } from '@reduxjs/toolkit'

import { actions, setRequest } from 'src/features/helpers'

import { defaultFilters } from './helpers'

import {
  cancelOrReversePayment,
  changeAppointmentStatus,
  changePaymentStatus,
  destroyAppointment,
  fetchAppointments,
  getAppointment,
  getAppointmentToPay,
  payAppointmentNow,
  storeAppointment,
  updateAppointment,
} from './actions'

export const initialState = {
  data: [],

  currentData: null,

  showFilters: true,

  filters: defaultFilters,

  requests: {},

  payNow: { isOpen: false, data: null },

  openModal: { action: '', isOpen: false, appointmentId: null },

  reqResult: { message: '', data: null, status: '', errors: [] },
}

function closeModal(state, modal) {
  state[modal] = initialState[modal]
  state.currentData = initialState.currentData
}

export const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointment: (state, action) => {
      state.currentData = action.payload
    },
    setAppointmentType: (state, action) => {
      state.type = action.payload
    },
    setOpenModal: (state, action) => {
      if (action.payload) {
        state.openModal = action.payload
      } else closeModal(state, 'openModal')
    },
    setFilters: (state, action) => {
      state.filters = action.payload
        ? { ...state.filters, ...action.payload }
        : initialState.filters
    },
    setShowFilters: (state, action) => {
      state.showFilters = action.payload
    },
    setPayNow: (state, action) => {
      if (action.payload) {
        state.payNow = action.payload
      } else closeModal(state, 'payNow')
    },
    resetReqResult: (state) => {
      state.reqResult = initialState.reqResult
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointments.pending, (state) => {
        setRequest(state, { req: 'fetchAppointments', status: 'pending' })
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.filters = { ...state.filters, ...action.payload.filters }
        setRequest(state, { req: 'fetchAppointments', status: 'fulfilled' })
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        setRequest(state, { req: 'fetchAppointments', status: 'rejected' })
      })
      .addCase(storeAppointment.pending, (state) => {
        setRequest(state, { req: 'storeAppointment', status: 'pending' })
      })
      .addCase(storeAppointment.fulfilled, (state, action) => {
        // const { appointment, message } = action.payload

        // state.reqResult.message = message
        // state.reqResult.data = appointment
        // state.reqResult.status = 'fulfilled'
        state.data = actions.addOne(state.data, action.payload)

        setRequest(state, { req: 'storeAppointment', status: 'fulfilled' })
      })
      .addCase(storeAppointment.rejected, (state, action) => {
        // state.reqResult.status = 'rejected'

        // if (action.meta.rejectedWithValue) {
        //   state.reqResult.message = action.payload.message
        //   state.reqResult.errors = action.payload?.details
        // } else {
        //   state.reqResult.message = action.error.message
        //   state.reqResult.errors = action.error?.details
        // }

        setRequest(state, { req: 'storeAppointment', status: 'rejected' })
      })
      .addCase(getAppointment.pending, (state) => {
        setRequest(state, { req: 'getAppointment', status: 'pending' })
      })
      .addCase(getAppointment.fulfilled, (state, action) => {
        state.currentData = action.payload
        setRequest(state, { req: 'getAppointment', status: 'fulfilled' })
      })
      .addCase(getAppointment.rejected, (state) => {
        setRequest(state, { req: 'getAppointment', status: 'rejected' })
      })
      .addCase(updateAppointment.pending, (state) => {
        setRequest(state, { req: 'updateAppointment', status: 'pending' })
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        actions.updateOne(state.data, action.payload)
        setRequest(state, { req: 'updateAppointment', status: 'fulfilled' })
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        setRequest(state, { req: 'updateAppointment', status: 'rejected' })
      })
      .addCase(destroyAppointment.pending, (state) => {
        setRequest(state, { req: 'destroyAppointment', status: 'pending' })
      })
      .addCase(destroyAppointment.fulfilled, (state, action) => {
        state.data = action.payload
        closeModal(state, 'openModal')
        setRequest(state, { req: 'destroyAppointment', status: 'fulfilled' })
      })
      .addCase(destroyAppointment.rejected, (state, action) => {
        setRequest(state, { req: 'destroyAppointment', status: 'rejected' })
      })
      .addCase(changeAppointmentStatus.pending, (state) => {
        setRequest(state, { req: 'changeAppointmentStatus', status: 'pending' })
      })
      .addCase(changeAppointmentStatus.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        setRequest(state, { req: 'changeAppointmentStatus', status: 'fulfilled' })
      })
      .addCase(changeAppointmentStatus.rejected, (state) => {
        state.openModal = initialState.openModal
        setRequest(state, { req: 'changeAppointmentStatus', status: 'rejected' })
      })
      .addCase(getAppointmentToPay.pending, (state) => {
        state.payNow.isOpen = true
        setRequest(state, { req: 'getAppointmentToPay', status: 'pending' })
      })
      .addCase(getAppointmentToPay.fulfilled, (state, action) => {
        state.currentData = action.payload
        setRequest(state, { req: 'getAppointmentToPay', status: 'fulfilled' })
      })
      .addCase(getAppointmentToPay.rejected, (state) => {
        state.payNow.isOpen = false
        setRequest(state, { req: 'getAppointmentToPay', status: 'rejected' })
      })
      .addCase(payAppointmentNow.pending, (state) => {
        setRequest(state, { req: 'payAppointmentNow', status: 'pending' })
      })
      .addCase(payAppointmentNow.fulfilled, (state, action) => {
        state.reqResult.status = 'fulfilled'
        state.reqResult.message = action.payload.message
        state.reqResult.data = action.payload.appointment
        state.data = actions.updateOne(state.data, action.payload.appointment)
        setRequest(state, { req: 'payAppointmentNow', status: 'fulfilled' })
      })
      .addCase(payAppointmentNow.rejected, (state, action) => {
        state.reqResult.status = 'rejected'
        state.reqResult.message = action.payload.message
        state.reqResult.errors = action.payload?.details
        setRequest(state, { req: 'payAppointmentNow', status: 'rejected' })
      })
      .addCase(changePaymentStatus.pending, (state) => {
        setRequest(state, { req: 'changePaymentStatus', status: 'pending' })
      })
      .addCase(changePaymentStatus.fulfilled, (state, action) => {
        state.data = action.payload
        closeModal(state, 'openModal')
        setRequest(state, { req: 'changePaymentStatus', status: 'fulfilled' })
      })
      .addCase(changePaymentStatus.rejected, (state) => {
        setRequest(state, { req: 'changePaymentStatus', status: 'rejected' })
      })
      .addCase(cancelOrReversePayment.pending, (state) => {
        setRequest(state, { req: 'cancelOrReversePayment', status: 'pending' })
      })
      .addCase(cancelOrReversePayment.fulfilled, (state, action) => {
        state.data = action.payload
        closeModal(state, 'openModal')
        setRequest(state, { req: 'cancelOrReversePayment', status: 'fulfilled' })
      })
      .addCase(cancelOrReversePayment.rejected, (state) => {
        closeModal(state, 'openModal')
        setRequest(state, { req: 'cancelOrReversePayment', status: 'rejected' })
      })
  },
})

export const {
  setAppointment,
  setAppointmentType,
  setOpenModal,
  setFilters,
  setShowFilters,
  setPayNow,
  resetReqResult,
} = slice.actions

export * from './actions'

export default slice.reducer
