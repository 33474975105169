import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],

  currentData: null,

  openModal: {
    action: null,
    isOpen: false,
    serviceId: null,
  },

  meta: { q: null },

  requests: {},
}

export const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload
    },
    setOpenModal: (state, action) => {
      if (action.payload) {
        state.openModal = action.payload
      } else {
        state.openModal = initialState.openModal
      }
    },
    setSearch: (state, action) => {
      state.meta.q = action.payload
    },
    setService: (state, action) => {
      state.currentData = action.payload
    },
  },
})

export const { setAction, setOpenModal, setSearch, setService } = slice.actions

export default slice.reducer

export * from './useFetchServicesQuery'
export * from './useCreateServiceMutation'
export * from './useDeleteServiceMutation'
export * from './useUpdateServiceMutation'
export * from './useToggleServiceMutation'
export * from './useMoveServicesToClinicMutation'
