import { useMutation, useQueryClient } from '@tanstack/react-query'

import { syncProfessionalServices } from 'src/lib/sante-api/professionals'

import { FETCH_PROFESSIONALS_QUERY_KEY } from './useFetchProfessionalsQuery'

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export function useSyncProfessionalServicesMutation(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: ({ id, ...data }) => syncProfessionalServices(id, data),
    onSettled: (data, error, { id }, context) => {
      if (id) queryClient.invalidateQueries({ queryKey: [FETCH_PROFESSIONALS_QUERY_KEY, id] })
    },
  })
}
