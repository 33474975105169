import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { downloadFile } from './utils'

export const appointmentGuide = createAsyncThunk('reports/appointmentGuide', async (id) => {
  const res = await santeApi.get(`admin/reports/appointments/${id}/guide`)
  return res.data
})

export const appointmentAnalytical = createAsyncThunk(
  'reports/appointmentAnalytical',
  async (params = {}) => {
    const res = await santeApi.get('admin/reports/appointments/analytical', { params })
    return res.data
  },
)

export const appointmentSynthetic = createAsyncThunk(
  'reports/appointmentSynthetic',
  async (params = {}) => {
    const res = await santeApi.get('admin/reports/appointments/synthetic', { params })
    return res.data
  },
)

export const appointmentBillingPerProfessional = createAsyncThunk(
  'reports/appointmentBillingPerProfessional',
  async (params = {}) => {
    const { application = 'pdf', ...queryParams } = params

    const res = await santeApi.get('admin/reports/appointments/billing-per-professional', {
      params: queryParams,
      headers: { Accept: `application/${application}; charset=utf-8` },
      responseType: application !== 'pdf' ? 'blob' : 'json',
    })

    if (application !== 'pdf') {
      downloadFile(res, 'faturamento-por-profissional.xlsx')
    } else {
      return res.data
    }
  },
)

export const customerAnalytical = createAsyncThunk(
  'reports/customerAnalytical',
  async (params = {}) => {
    const { application = 'pdf', ...queryParams } = params

    const res = await santeApi.get('admin/reports/customers/analytical', {
      params: queryParams,
      headers: { Accept: `application/${application}; charset=utf-8` },
      responseType: application !== 'pdf' ? 'blob' : 'json',
    })

    if (application !== 'pdf') {
      downloadFile(res, 'controle-de-clientes.xlsx')
    } else {
      return res.data
    }
  },
)

export const serviceListRegistered = createAsyncThunk(
  'reports/serviceListRegistered',
  async (params = {}) => {
    const res = await santeApi.get('admin/reports/services/list', { params })
    return res.data
  },
)

export const serviceScheduled = createAsyncThunk(
  'reports/serviceScheduled',
  async (params = {}) => {
    const res = await santeApi.get('admin/reports/services/scheduled', { params })
    return res.data
  },
)
