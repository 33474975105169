import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions, checkRequest } from 'src/features/helpers'

import {
  addModuleWithPermissions,
  editModuleAndPermissions,
  removeModuleWithPermissions,
} from './helpers'

export const fetchRoles = createAsyncThunk(
  'authorization/fetchRoles',
  async ({ forceFetch = false } = {}) => {
    const res = await santeApi.get('admin/roles')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      if (!getState().auth.isAuthenticated) return false
      return checkRequest(getState().authorization, 'fetchRoles', 'fulfilled') ? forceFetch : true
    },
  },
)

export const storeRole = createAsyncThunk('authorization/storeRole', async (data, { getState }) => {
  const res = await santeApi.post('admin/roles', data)
  return actions.addOne(getState().authorization.roles, res.data)
})

export const updateRole = createAsyncThunk(
  'authorization/updateRole',
  async ({ id, data }, { getState }) => {
    const res = await santeApi.put(`admin/roles/${id}`, data)
    return actions.updateOne(getState().authorization.roles, res.data)
  },
)

export const destroyRole = createAsyncThunk(
  'authorization/destroyRole',
  async (id, { getState }) => {
    await santeApi.delete(`admin/roles/${id}`)
    return actions.removeOne(getState().authorization.roles, id)
  },
)

export const fetchModules = createAsyncThunk(
  'authorization/fetchModules',
  async ({ forceFetch = false } = {}) => {
    const res = await santeApi.get('admin/modules')
    return res.data.sort((moduleA, moduleB) =>
      moduleA.name > moduleB.name ? 1 : moduleA.name < moduleB.name ? -1 : 0,
    )
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      if (!getState().auth.isAuthenticated) return false
      return checkRequest(getState().authorization, 'fetchModules', 'fulfilled') ? forceFetch : true
    },
  },
)

export const storeModule = createAsyncThunk(
  'authorization/storeModule',
  async (data, { getState }) => {
    const res = await santeApi.post('admin/modules', data)
    return addModuleWithPermissions(getState().authorization, res.data)
  },
)

export const updateModule = createAsyncThunk(
  'authorization/updateModule',
  async ({ id, data }, { getState }) => {
    const res = await santeApi.put(`admin/modules/${id}`, data)
    return editModuleAndPermissions(getState().authorization, data, res.data)
  },
)

export const destroyModule = createAsyncThunk(
  'authorization/destroyModule',
  async (id, { getState }) => {
    await santeApi.delete(`admin/modules/${id}`)
    return removeModuleWithPermissions(getState().authorization, id)
  },
)

export const fetchPermissions = createAsyncThunk(
  'authorization/fetchPermissions',
  async ({ forceFetch = false } = {}) => {
    const res = await santeApi.get('admin/permissions')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      if (!getState().auth.isAuthenticated) return false
      return checkRequest(getState().authorization, 'fetchPermissions', 'fulfilled')
        ? forceFetch
        : true
    },
  },
)

export const storePermission = createAsyncThunk(
  'authorization/storePermission',
  async (data, { getState }) => {
    const res = await santeApi.post('admin/permissions', data)
    return actions.addOne(getState().authorization.permissions, res.data)
  },
)

export const updatePermission = createAsyncThunk(
  'authorization/updatePermission',
  async ({ id, data }, { getState }) => {
    const res = await santeApi.put(`admin/permissions/${id}`, data)
    return actions.updateOne(getState().authorization.permissions, res.data)
  },
)

export const destroyPermission = createAsyncThunk(
  'authorization/destroyPermission',
  async (id, { getState }) => {
    await santeApi.delete(`admin/permissions/${id}`)
    return actions.removeOne(getState().authorization.permissions, id)
  },
)

export const syncRoleAndPermissionsToUser = createAsyncThunk(
  'authorization/syncRoleAndPermissionsToUser',
  async ({ id, data }) => {
    const res = await santeApi.put(`admin/authorization/${id}`, data)
    return res.data
  },
)
