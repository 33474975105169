import { useQuery } from '@tanstack/react-query'

import { getUnlinkedProfessionals } from 'src/lib/sante-api/specialties'

export const FETCH_UNLINKED_PROFESSIONALS_QUERY_KEY = 'get-unlinked-professionals'

/**
 * @param {number=} specialtyId
 * @param {number=} clinicId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useGetUnlinkedProfessionalsQuery(specialtyId, clinicId, options = {}) {
  const queryKey = [FETCH_UNLINKED_PROFESSIONALS_QUERY_KEY, specialtyId, clinicId]

  const { data: unlinkedProfessionals = [], ...rest } = useQuery({
    enabled: !!specialtyId && !!clinicId,
    ...options,
    queryKey,
    queryFn: () => getUnlinkedProfessionals(specialtyId, clinicId),
  })

  return { ...rest, unlinkedProfessionals, queryKey }
}
