import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createAttendanceSchedule } from 'src/lib/sante-api/attendance-schedules'

import { GET_SCHEDULES_QUERY_KEY } from './useGetSchedulesQuery'

/**
 * @param {'clinic'|'professional'} entity
 * @param {number=} entityId
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useCreateScheduleMutation(entity, entityId, options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [GET_SCHEDULES_QUERY_KEY, entity, entityId], [entity, entityId])

  return useMutation({
    ...options,

    mutationFn: createAttendanceSchedule,

    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      console.log({ previous })

      queryClient.setQueryData(queryKey, (old) => [
        ...old,
        {
          ...item,
          id: old.length + 1,
          day: `${item.day}T00:00:00.000000Z`,
          times: item.times.map((time) => `${item.day}T${time}:00.000000Z`),
          used_times: [],
          created_at: new Date(),
        },
      ])

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
}
