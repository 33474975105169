import santeApi from 'src/services/sante-api'

/**
 * @param {Object} params
 * @param {number=} params.clc
 * @param {number=} params.pro
 */
export async function fetchAvailableTimes(params = {}) {
  const res = await santeApi.get('v1/attendances/schedules/available-times', { params })

  return res.data
}

/**
 * @param {'clinic'|'professional'} modelType
 * @param {number} modelId
 */
export async function getAttendanceSchedules(modelType, modelId) {
  const res = await santeApi.get(`v1/attendances/schedules/${modelType}/${modelId}`)

  return res.data
}

export async function createAttendanceSchedule(data) {
  const res = await santeApi.post('v1/attendances/schedules', data)

  return res.data
}

export async function updateAttendanceSchedule(id, data) {
  const res = await santeApi.put(`v1/attendances/schedules/${id}`, data)

  return res.data
}

export async function deleteAttendanceSchedule(id) {
  await santeApi.delete(`v1/attendances/schedules/${id}`)
}

/**
 * @param {'clinic'|'professional'} modelType
 * @param {number} modelId
 * @param {string} day
 * @returns {Promise<{ status: boolean }>}
 */
export async function checkIfDayAlreadyExists(modelType, modelId, day) {
  const res = await santeApi.get(`v1/attendances/schedules/${modelType}/${modelId}/exists/${day}`)
  return res.data
}
