import { configureStore } from '@reduxjs/toolkit'

import appointments from 'src/features/appointments'

import attendanceTypes from 'src/features/attendance-types/slice'

import auth from 'src/features/auth'

import authorization from 'src/features/authorization'

import callSchedules from 'src/features/call-schedules'

import creditCards from 'src/features/credit-cards'

import customers from 'src/features/customers'

import highlights from 'src/features/highlights'

import layout from 'src/features/layout'

import modalities from 'src/features/modalities'

import notifications from 'src/features/notifications'

import partners from 'src/features/partners'

import professionalAvailabilities from 'src/features/professional-availabilities'

import professionalCategories from 'src/features/professional-categories'

import professionals from 'src/features/professionals'

import reports from 'src/features/reports'

import representatives from 'src/features/representatives'

import services from 'src/features/services'

import specialties from 'src/features/specialties/slice'

import specialtyPrices from 'src/features/specialty-prices/slice'

import statistics from 'src/features/statistics'

import users from 'src/features/users'

import { rtkQueryApi } from 'src/services/rtk-query'

const store = configureStore({
  reducer: {
    [rtkQueryApi.reducerPath]: rtkQueryApi.reducer,
    appointments,
    attendanceTypes,
    auth,
    authorization,
    callSchedules,
    creditCards,
    customers,
    highlights,
    layout,
    modalities,
    notifications,
    partners,
    professionalAvailabilities,
    professionalCategories,
    professionals,
    reports,
    representatives,
    services,
    specialties,
    specialtyPrices,
    statistics,
    users,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryApi.middleware),
})

export default store
