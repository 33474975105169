export const Gender = {
  MALE: 'male',

  FEMALE: 'female',

  options() {
    return [
      { label: 'Masculino', value: this.MALE },
      { label: 'Feminino', value: this.FEMALE },
    ]
  },

  findOption(value) {
    return this.options().find((option) => option.value === value)
  },
}
