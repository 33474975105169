import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import * as api from './actions'

const adapter = createEntityAdapter()

const initialState = adapter.getInitialState({
  modalState: '',

  selectedDay: null,

  selectedProfessional: null,

  requests: {},
})

const slice = createSlice({
  name: 'professionalAvailabilities',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.selectedDay = action.payload
      state.modalState = 'opened'
    },
    closeModal: (state) => {
      state.modalState = initialState.modalState
      state.selectedProfessional = initialState.selectedProfessional
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload
    },
    setSelectedProfessional: (state, action) => {
      state.selectedProfessional = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(api.fetchProAvailabilities.pending, (state) => {
      setRequest(state, { req: 'fetchProAvailabilities', status: 'pending' })
    })
    builder.addCase(api.fetchProAvailabilities.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      setRequest(state, { req: 'fetchProAvailabilities', status: 'fulfilled' })
    })
    builder.addCase(api.fetchProAvailabilities.rejected, (state) => {
      setRequest(state, { req: 'fetchProAvailabilities', status: 'rejected' })
    })
    builder.addCase(api.fetchAvailableDays.pending, (state) => {
      setRequest(state, { req: 'fetchAvailableDays', status: 'pending' })
    })
    builder.addCase(api.fetchAvailableDays.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      setRequest(state, { req: 'fetchAvailableDays', status: 'fulfilled' })
    })
    builder.addCase(api.fetchAvailableDays.rejected, (state) => {
      setRequest(state, { req: 'fetchAvailableDays', status: 'rejected' })
    })
    builder.addCase(api.saveAvailabilitiesChanges.pending, (state) => {
      setRequest(state, { req: 'saveAvailabilitiesChanges', status: 'pending' })
    })
    builder.addCase(api.saveAvailabilitiesChanges.fulfilled, (state, action) => {
      adapter.addMany(state, action.payload.toAdd)

      adapter.updateMany(state, action.payload.toUpdate)

      adapter.removeMany(state, action.payload.toRemove)

      state.modalState = initialState.modalState
      state.selectedProfessional = initialState.selectedProfessional

      setRequest(state, { req: 'saveAvailabilitiesChanges', status: 'fulfilled' })
    })
    builder.addCase(api.saveAvailabilitiesChanges.rejected, (state) => {
      setRequest(state, { req: 'saveAvailabilitiesChanges', status: 'rejected' })
    })
  },
})

export * from './actions'

export const { closeModal, openModal, setSelectedDay, setSelectedProfessional } = slice.actions

export const proAvailabilitiesSelectors = adapter.getSelectors((state) => {
  return state.professionalAvailabilities
})

export default slice.reducer
