import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toggleProfessional } from 'src/lib/sante-api/professionals'

import {
  FETCH_PROFESSIONALS_QUERY_KEY,
  getQueryFromSessionStorage,
} from './useFetchProfessionalsQuery'

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export function useToggleProfessionalMutation(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: toggleProfessional,
    onSettled: (data, error, id, context) => {
      const queryKey = getQueryFromSessionStorage()

      if (queryKey) queryClient.invalidateQueries({ queryKey })
      if (id) queryClient.invalidateQueries({ queryKey: [FETCH_PROFESSIONALS_QUERY_KEY, id] })
    },
  })
}
