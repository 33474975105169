import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions } from 'src/features/helpers'

export const fetchCallSchedules = createAsyncThunk('callSchedules/fetch', async () => {
  const res = await santeApi.get('admin/call-schedules')
  return res.data
})

export const updateCallScheduling = createAsyncThunk(
  'callSchedules/update',
  async ({ id, data }, { getState }) => {
    await santeApi.put(`admin/call-schedules/${id}`, data)
    return actions.updateOne(getState().callSchedules.data, { ...data, id })
  },
)

export const destroyCallScheduling = createAsyncThunk(
  'callSchedules/destroy',
  async (id, { getState }) => {
    await santeApi.delete(`admin/call-schedules/${id}`)
    return actions.removeOne(getState().callSchedules.data, id)
  },
)
