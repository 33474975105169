import santeApi from 'src/services/sante-api'

/**
 *
 * @param {Object} params
 * @param {number=} params.clc
 * @returns {Promise<import('./type.d').Professional[]>}
 */
export async function fetchProfessionals(params = {}) {
  return (await santeApi.get(`admin/professionals`, { params })).data
}

/**
 *
 * @param {object} data
 * @returns {Promise<import('./type.d').Professional>}
 */
export async function createProfessional(data) {
  const res = await santeApi.post('admin/professionals', data, {
    headers: { 'content-Type': 'multipart/form-data' },
  })

  return res.data
}

/**
 *
 * @param {number} id
 * @returns {Promise<import('./type.d').Professional>}
 */
export async function getProfessionalById(id) {
  return (await santeApi.get(`admin/professionals/${id}`)).data
}

/**
 *
 * @param {number} id
 * @param {object} data
 * @returns {Promise<import('./type.d').Professional>}
 */
export async function updateProfessional(id, data) {
  return (await santeApi.put(`admin/professionals/${id}`, data)).data
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function deleteProfessional(id) {
  await santeApi.delete(`admin/professionals/${id}`)
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function restoreProfessional(id) {
  await santeApi.post(`admin/professionals/${id}/restore`)
}

/**
 * Toggle professional
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function toggleProfessional(id) {
  await santeApi.patch(`admin/professionals/${id}/toggle`)
}

/**
 * Sync professional services
 *
 * @param {number} id
 * @param {object} data
 * @param {number[]} data.attach
 * @returns {Promise<void>}
 */
export async function syncProfessionalServices(id, data) {
  await santeApi.post(`admin/professionals/${id}/syncs/services`, data)
}

/**
 * Sync professional specialties
 *
 * @param {number} id
 * @param {object} data
 * @param {number[]} data.attach
 * @returns {Promise<void>}
 */
export async function syncProfessionalSpecialties(id, data) {
  await santeApi.post(`admin/professionals/${id}/syncs/specialties`, data)
}
