import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateAttendanceSchedule } from 'src/lib/sante-api/attendance-schedules'

import { GET_SCHEDULES_QUERY_KEY } from './useGetSchedulesQuery'

/**
 *
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useUpdateScheduleMutation(entity, entityId, options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [GET_SCHEDULES_QUERY_KEY, entity, entityId], [entity, entityId])

  return useMutation({
    ...options,

    mutationFn: ({ id, ...data }) => updateAttendanceSchedule(id, data),

    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) => {
        const formattedItem = {
          ...item,
          day: `${item.day}T00:00:00.000000Z`,
          times: item.times.map((time) => `${item.day}T${time}:00.000000Z`),
        }

        console.log({ formattedItem })

        return old.map((oldItem) =>
          oldItem.id === item.id ? { ...oldItem, ...formattedItem } : oldItem,
        )
      })

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
}
