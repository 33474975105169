import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import * as api from './actions'

const adapter = createEntityAdapter()

const initialState = {
  selected: null,

  modalState: { action: '', isOpen: false },

  requests: {},
}

const slice = createSlice({
  name: 'representatives',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setRepresentatives: adapter.setAll,
    setRepresentative: (state, action) => {
      state.selected = action.payload ?? initialState.selected
    },
  },
  extraReducers: (builder) => {
    builder.addCase(api.handleModalState, (state, action) => {
      state.selected = action.payload.selected
      state.modalState = action.payload.modalState
    })
    builder.addCase(api.fetchRepresentatives.pending, (state) => {
      setRequest(state, { req: 'fetchRepresentatives', status: 'pending' })
    })
    builder.addCase(api.fetchRepresentatives.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      setRequest(state, { req: 'fetchRepresentatives', status: 'fulfilled' })
    })
    builder.addCase(api.fetchRepresentatives.rejected, (state) => {
      setRequest(state, { req: 'fetchRepresentatives', status: 'rejected' })
    })
    builder.addCase(api.storeRepresentative.pending, (state) => {
      setRequest(state, { req: 'storeRepresentative', status: 'pending' })
    })
    builder.addCase(api.storeRepresentative.fulfilled, (state, action) => {
      adapter.addOne(state, action.payload)
      state.modalState = initialState.modalState
      setRequest(state, { req: 'storeRepresentative', status: 'fulfilled' })
    })
    builder.addCase(api.storeRepresentative.rejected, (state) => {
      setRequest(state, { req: 'storeRepresentative', status: 'rejected' })
    })
    builder.addCase(api.toggleIsPropertyRep.pending, (state, { meta: { arg } }) => {
      setRequest(state, { req: 'toggleIsPropertyRep', status: 'pending', meta: { ...arg } })
    })
    builder.addCase(api.toggleIsPropertyRep.fulfilled, (state, action) => {
      adapter.updateOne(state, action.payload)
      setRequest(state, { req: 'toggleIsPropertyRep', status: 'fulfilled' })
    })
    builder.addCase(api.toggleIsPropertyRep.rejected, (state) => {
      setRequest(state, { req: 'toggleIsPropertyRep', status: 'rejected' })
    })
    builder.addCase(api.getRepresentative.pending, (state) => {
      setRequest(state, { req: 'getRepresentative', status: 'pending' })
    })
    builder.addCase(api.getRepresentative.fulfilled, (state, action) => {
      state.selected = action.payload
      setRequest(state, { req: 'getRepresentative', status: 'fulfilled' })
    })
    builder.addCase(api.getRepresentative.rejected, (state) => {
      setRequest(state, { req: 'getRepresentative', status: 'rejected' })
    })
    builder.addCase(api.updateRepresentative.pending, (state) => {
      setRequest(state, { req: 'updateRepresentative', status: 'pending' })
    })
    builder.addCase(api.updateRepresentative.fulfilled, (state, action) => {
      adapter.updateOne(state, action.payload)
      state.modalState = initialState.modalState
      setRequest(state, { req: 'updateRepresentative', status: 'fulfilled' })
    })
    builder.addCase(api.updateRepresentative.rejected, (state) => {
      setRequest(state, { req: 'updateRepresentative', status: 'rejected' })
    })
    builder.addCase(api.destroyRepresentative.pending, (state) => {
      setRequest(state, { req: 'destroyRepresentative', status: 'pending' })
    })
    builder.addCase(api.destroyRepresentative.fulfilled, (state, action) => {
      adapter.removeOne(state, action.payload.id)
      state.modalState = initialState.modalState
      setRequest(state, { req: 'destroyRepresentative', status: 'fulfilled' })
    })
    builder.addCase(api.destroyRepresentative.rejected, (state) => {
      state.selected = initialState.selected
      state.modalState = initialState.modalState
      setRequest(state, { req: 'destroyRepresentative', status: 'rejected' })
    })
  },
})

export const { setRepresentative, setRepresentatives } = slice.actions

export const representativesSelectors = adapter.getSelectors((state) => state.representatives)

export * from './actions'

export default slice.reducer
