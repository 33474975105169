import { useQuery } from '@tanstack/react-query'

import { getUnlinkedClinics } from 'src/lib/sante-api/specialties'

export const FETCH_UNLINKED_CLINICS_QUERY_KEY = 'get-unlinked-clinics'

/**
 * @param {number=} specialtyId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useGetUnlinkedClinicsQuery(specialtyId, options = {}) {
  const { data: unlinkedClinics = [], ...rest } = useQuery({
    enabled: !!specialtyId,
    ...options,
    queryKey: [FETCH_UNLINKED_CLINICS_QUERY_KEY, specialtyId],
    queryFn: () => getUnlinkedClinics(specialtyId),
  })

  return { ...rest, unlinkedClinics }
}
