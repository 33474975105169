import { rtkQueryApi } from 'src/services/rtk-query'

export const professionalCategoriesApi = rtkQueryApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get professionals
     */
    getProfessionalCategories: build.query({
      query: ({ params = {} } = {}) => ({ url: 'admin/professional-categories', params }),
      providesTags: (result) => [
        ...result.map(({ id }) => ({ type: 'ProfessionalCategory', id })),
        { type: 'ProfessionalCategory', id: 'LIST' },
      ],
    }),

    createProfessionalCategory: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'admin/professional-categories',
      }),
      invalidatesTags: [{ type: 'ProfessionalCategory', id: 'LIST' }],
    }),

    updateProfessionalCategory: build.mutation({
      query: ({ id, ...body }) => ({
        body,
        method: 'PUT',
        url: `admin/professional-categories/${id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ProfessionalCategory', id },
        { type: 'ProfessionalByClinic', id: 'LIST' },
      ],
    }),

    deleteProfessionalCategory: build.mutation({
      query: ({ id, force = false }) => ({
        method: 'DELETE',
        url: `admin/professional-categories/${id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ProfessionalCategory', id },
        { type: 'ProfessionalByClinic', id: 'LIST' },
      ],
    }),
  }),
})
