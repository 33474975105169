import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

export const fetchProAvailabilities = createAsyncThunk(
  'professionalAvailabilities/fetchProAvailabilities',
  async (id) => {
    const res = await santeApi.get(`admin/professionals/${id}/availabilities`)
    return res.data
  },
)

export const fetchAvailableDays = createAsyncThunk(
  'professionalAvailabilities/fetchAvailableDays',
  async (id) => {
    const res = await santeApi.get(`admin/professionals/${id}/availabilities/days`)
    return res.data
  },
)

export const saveAvailabilitiesChanges = createAsyncThunk(
  'professionalAvailabilities/saveAvailabilitiesChanges',
  async ({ id, data }) => {
    const payload = { toAdd: [], toUpdate: [], toRemove: [] }

    try {
      if (data.toAdd.length > 0) {
        const res = await santeApi.post(`admin/professionals/${id}/availabilities`, {
          availabilities: data.toAdd,
        })

        payload.toAdd = res.data
      }

      if (data.toUpdate.length > 0) {
        const res = await santeApi.put(`admin/professionals/${id}/availabilities`, {
          availabilities: data.toUpdate,
        })

        payload.toUpdate = res.data
      }
    } catch (error) {
      console.log(error)
    }

    return payload
  },
)
