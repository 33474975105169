import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from 'src/features/helpers'

export const fetchPartners = createAsyncThunk(
  'partners/fetchPartners',
  async () => {
    const res = await santeApi.get('admin/partners')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().partners, 'fetchStatus', 'fulfilled') ? forceFetch : true
    },
  },
)

export const storePartner = createAsyncThunk('partners/storePartner', async (data) => {
  const res = await santeApi.post('admin/partners', data)
  return res.data
})

export const updatePartner = createAsyncThunk('partners/updatePartner', async ({ id, data }) => {
  const res = await santeApi.post(`admin/partners/${id}`, data)
  return res.data
})

export const destroyPartner = createAsyncThunk('partners/destroyPartner', async (id) => {
  await santeApi.delete(`admin/partners/${id}`)
  return { id }
})

export const togglePartnerStatus = createAsyncThunk('partners/togglePartnerStatus', async (id) => {
  const res = await santeApi.patch(`admin/partners/${id}/toggle`)
  return res.data
})
