const config = {
  /**
   * Santé API URL
   */
  apiURL: `${process.env.REACT_APP_HOST}/api`,
  /**
   * Token type using in authentication
   */
  tokenType: 'Bearer',
  /**
   * Name of keys used in localStorage or sessionStorage
   */
  localKeys: {
    user: '@Sante:USER',
    token: '@Sante:TOKEN',
    syncRolePermissions: '@Sante:Sync:ROLE-PERMISSIONS',
  },
  /**
   * Access points available on Santé API
   */
  endpoints: {
    /**
     * Base path to ACL
     */
    acl: 'admin/acl',
    /**
     * Base path to addresses
     */
    addresses: 'admin/addresses',
    /**
     * Base path to appointments
     */
    appointments: 'admin/appointments',
    /**
     * Base path to callSchedules
     */
    callSchedules: 'admin/call-schedules',
    /**
     * Base path to categories
     */
    categories: 'admin/categories',
    /**
     * Base path to clinics
     */
    clinics: 'admin/clinics',
    /**
     * Base path to customers
     */
    customers: 'admin/customers',
    /**
     * Base path to dashboard
     */
    dashboard: 'admin/dashboard',
    /**
     * Base path to dependents
     */
    dependents: 'admin/dependents',
    /**
     * Base path to modalities
     */
    modalities: 'admin/modalities',
    /**
     * Base path to paymentMethods
     */
    paymentMethods: 'admin/payment-methods',
    /**
     * Base path to payments
     */
    payments: 'admin/payments',
    /**
     * Base path to permissions
     */
    permissions: 'admin/permissions',
    /**
     * Base path to professionalCategories
     */
    professionalCategories: 'admin/professional-categories',
    /**
     * Base path to professionals
     */
    professionals: 'admin/professionals',
    /**
     * Base path to profile
     */
    profile: 'admin/profile',
    /**
     * Base path to reports
     */
    reports: 'admin/reports',
    /**
     * Base path to roles
     */
    roles: 'admin/roles',
    /**
     * Base path to santeCards
     */
    santeCards: 'admin/sante-cards',
    /**
     * Base path to services
     */
    services: 'admin/services',
    /**
     * Base path to specialties
     */
    specialties: 'admin/specialties',
    /**
     * Base path to users
     */
    users: 'admin/users',
    /**
     * Auth routes
     */
    auth: {
      /**
       * Forgot password
       */
      forgotPassword: 'admin/forgot-password',
      /**
       * Reset password
       */
      resetPassword: 'admin/reset-password',
      /**
       * Send email verification link
       */
      sendEmailVerificationLink: 'admin/email/verification-notification',
      /**
       * Verify email
       */
      verifyEmail: 'admin/verify-email',
    },
    /**
     * User session
     */
    session: {
      /**
       * Get the data from the logged in user
       */
      user: 'v1/user',
      /**
       * App Sign In
       */
      login: 'v1/login',
      /**
       * App Sign Out
       */
      logout: 'v1/logout',
      /**
       * Session Refresh
       */
      refresh: 'v1/refresh',
    },
  },
}

export default config
