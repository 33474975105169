import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CustomerStatus, toggleCustomerStatus } from 'src/lib/sante-api/customers'

import { FETCH_CUSTOMERS_QUERY_KEY } from './useGetCustomerByIdQuery'

/**
 * @param {import('@tanstack/react-query').UseMutationOptions & {
 * customer: import('src/lib/sante-api/customers').Customer|null
 * }} options
 */
export function useToggleCustomerStatusMutation({ customer, ...options }) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [FETCH_CUSTOMERS_QUERY_KEY, customer?.id], [customer?.id])

  return useMutation({
    ...options,

    mutationFn: () => toggleCustomerStatus(customer.id),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey) ?? customer

      const status = CustomerStatus.isActive(previous?.status)
        ? CustomerStatus.SUSPENDED
        : CustomerStatus.ACTIVE

      queryClient.setQueryData(queryKey, (old) => ({
        ...old,
        status,
        status_display: CustomerStatus.display(status),
      }))

      return { previous, newStatus: status }
    },

    onSuccess: (data, variables, context) => {
      if (typeof options?.onSuccess === 'function') {
        options.onSuccess({ status: context.newStatus }, variables, context)
      }
    },

    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey })

      if (typeof options?.onSettled === 'function') {
        options.onSettled(data, error, variables, context)
      }
    },
  })
}
