export const MaritalStatus = {
  SINGLE: 'single',
  MARRIED: 'married',
  DIVORCED: 'divorced',
  OTHER: 'other',

  options() {
    return [
      { label: 'Solteiro(a)', value: this.SINGLE },
      { label: 'Casado(a)', value: this.MARRIED },
      { label: 'Divorciado(a)', value: this.DIVORCED },
      { label: 'Outro', value: this.OTHER },
    ]
  },

  /**
   *
   * @param {string} value
   * @returns {string|null}
   */
  findLabel(value) {
    return this.findOption(value)?.label ?? null
  },

  /**
   *
   * @param {string} value
   * @returns {{ label: string, value: string }}
   */
  findOption(value) {
    return this.options().find((option) => option.value === value)
  },
}
