import { useQuery } from '@tanstack/react-query'

import { fetchServices } from 'src/lib/sante-api/services'

export const FETCH_SERVICES_QUERY_KEY = 'services'

/**
 * @param {import('src/types/sante-api').FetchServicesParams} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useFetchServicesQuery(params, options) {
  return useQuery({
    ...options,
    queryFn: () => fetchServices(params),
    queryKey: [FETCH_SERVICES_QUERY_KEY, params],
  })
}
