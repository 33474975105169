import moment from 'moment'

import { AppointmentStatus } from 'src/lib/sante-api/constants'

export const defaultFilters = {
  pro: null,
  clc: null,
  att: null,
  ctm: null,
  by: 'created_at',
  status: AppointmentStatus.options()[0],
  end: moment().endOf('month').format('YYYY-MM-DD'),
  start: moment().startOf('months').subtract(1, 'months').format('YYYY-MM-DD'),
}

export function filterAppointmentsByUrlParams({ data = [], filters = defaultFilters }) {
  let filtered = [...data]

  try {
    if (filters.att) {
      const id = parseInt(filters.att)
      filtered = filtered.filter((item) => item.category_id === id)
    }

    if (filters.status) {
      filtered = filtered.filter((item) => item.status === filters.status?.value)
    }

    if (filters.ctm) {
      filtered = filtered.filter((item) => item.customer_id === filters.ctm)
    }

    if (filters.clc) {
      filtered = filtered.filter((item) => item.clinic_id === filters.clc)
    }

    if (filters.pro) {
      filtered = filtered.filter((item) => item.professional_id === filters.pro)
    }
  } catch (error) {
    console.log(error)
  } finally {
    return filtered
  }
}
