import 'core-js'
import 'react-app-polyfill/stable'

import 'moment/locale/pt-br'

import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'

import { QueryClientProvider } from '@tanstack/react-query'

import { AuthorizationProvider } from './contexts/authorization'
import { BroadcastProvider } from './contexts/broadcast'

import App from './App'

import reportWebVitals from './reportWebVitals'

import store from './app/store'
import { queryClient } from './lib/query-client'

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AuthorizationProvider>
        <BroadcastProvider>
          <App />
        </BroadcastProvider>
      </AuthorizationProvider>
    </Provider>
  </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
