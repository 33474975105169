import santeApi from 'src/services/sante-api'

/**
 *
 * @param {import('./types.d').FetchCustomersParams} params
 * @returns {Promise<import('./types.d').FetchCustomersResponse>}
 */
export async function fetchCustomers(params = {}) {
  const res = await santeApi.get('admin/customers', { params })

  const { data = [], ...pagination } = res?.data ?? {}

  return { data, pagination }
}

/**
 *
 * @param {object} data
 * @returns {Promise<import('./types.d').Customer>}
 */
export async function createCustomer(data) {
  return (await santeApi.post('admin/customers', data))?.data
}

/**
 *
 * @param {number} id
 * @returns {Promise<import('./types.d').Customer>}
 */
export async function getCustomer(id) {
  return (await santeApi.get(`admin/customers/${id}`))?.data
}

/**
 *
 * @param {number} id
 * @param {object} data
 * @returns {Promise<import('./types.d').Customer>}
 */
export async function updateCustomer(id, data) {
  const res = await santeApi.put(`admin/customers/${id}`, data)
  return res.data
}

/**
 *
 * @param {number} id
 * @param {boolean} force
 * @returns {Promise<void>}
 */
export async function deleteCustomer(id, force = false) {
  await santeApi.delete(`admin/customers/${id}`, { params: { force } })
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function restoreCustomer(id) {
  await santeApi.post(`admin/customers/${id}/restore`)
}

/**
 * Toggle customer status between 'active' or 'suspended'
 *
 * @param {number} id
 * @return {Promise<Partial<import('./types.d').Customer>>}
 */
export async function toggleCustomerStatus(id) {
  const res = await santeApi.patch(`admin/customers/${id}/toggle-active`)
  return res.data
}

/**
 *
 * @param {number} id
 * @returns {Promise<import('./types.d').CheckInCustomerResponse>}
 */
export async function checkInCustomer(id) {
  const res = await santeApi.get(`admin/customers/${id}/checkout`, {
    customToast: true,
  })

  const { data: customer, errorKeys = [], errors = [], message = '' } = res.data

  return { customer, errorKeys, errors, message }
}

/**
 *
 * @param {number} customer
 * @returns {Promise<import('src/lib/sante-api/subscriptions').Subscription[]>}
 */
export async function getCustomerSubscriptions(customer) {
  return (await santeApi.get(`admin/customers/${customer}/subscriptions`))?.data
}
