import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createSpecialty } from 'src/lib/sante-api/specialties'

import { FETCH_SPECIALTIES_QUERY_KEY } from './useFetchSpecialtiesQuery'

/**
 *
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useCreateSpecialtyMutation(options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [FETCH_SPECIALTIES_QUERY_KEY, {}], [])

  return useMutation({
    ...options,

    mutationFn: createSpecialty,

    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) => [
        ...old,
        {
          id: old.length + 1,
          name: item.name,
          attendance_type_ids: item?.attendance_types ?? [],
          updated_at: new Date().toISOString(),
        },
      ])

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
    },
  })
}
