import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from 'src/configs/sante-api'

import { getTokenFromStorage } from 'src/utils/auth'

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const rtkQueryApi = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'api',
  keepUnusedDataFor: 300, // 5 minutes
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiURL,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getTokenFromStorage()

      if (token) headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
  }),
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'AttendanceType',
    'Category',
    'Clinic',
    'Modality',
    'Professional',
    'ProfessionalByClinic',
    'ProfessionalCategory',
    'Service',
    'ServiceByClinic',
    'Specialty',
    'SpecialtyByClinic',
    'UNAUTHORIZED',
    'UNKNOWN_ERROR',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: (build) => ({
    /**
     * Refetch errored queries
     */
    refetchErroredQueries: build.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['UNKNOWN_ERROR'],
    }),
  }),
})

export const enhancedApi = rtkQueryApi.enhanceEndpoints({ endpoints: () => ({}) })
