import { createSlice } from '@reduxjs/toolkit'

import { actions, setRequest } from 'src/features/helpers'

import {
  destroyHighlight,
  fetchHighlights,
  storeHighlight,
  toggleHighlightStatus,
  updateHighlight,
} from './actions'

const initialState = {
  data: [],

  currentData: null,

  openModal: { action: '', isOpen: false },

  requests: {},
}

export const slice = createSlice({
  name: 'highlights',
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload ? action.payload : initialState.openModal
    },
    setHighlight: (state, action) => {
      state.currentData = action.payload ?? initialState.currentData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHighlights.pending, (state) => {
        setRequest(state, { req: 'fetchHighlights', status: 'pending' })
      })
      .addCase(fetchHighlights.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'fetchHighlights', status: 'fulfilled' })
      })
      .addCase(fetchHighlights.rejected, (state) => {
        setRequest(state, { req: 'fetchHighlights', status: 'rejected' })
      })
      .addCase(storeHighlight.pending, (state) => {
        setRequest(state, { req: 'storeHighlight', status: 'pending' })
      })
      .addCase(storeHighlight.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.data = actions.addOne(state.data, action.payload)
        setRequest(state, { req: 'storeHighlight', status: 'fulfilled' })
      })
      .addCase(storeHighlight.rejected, (state) => {
        setRequest(state, { req: 'storeHighlight', status: 'rejected' })
      })
      .addCase(updateHighlight.pending, (state) => {
        setRequest(state, { req: 'updateHighlight', status: 'pending' })
      })
      .addCase(updateHighlight.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        state.data = actions.updateOne(state.data, action.payload)
        setRequest(state, { req: 'updateHighlight', status: 'fulfilled' })
      })
      .addCase(updateHighlight.rejected, (state) => {
        setRequest(state, { req: 'updateHighlight', status: 'rejected' })
      })
      .addCase(destroyHighlight.pending, (state) => {
        setRequest(state, { req: 'destroyHighlight', status: 'pending' })
      })
      .addCase(destroyHighlight.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        state.data = actions.removeOne(state.data, action.payload.id)
        setRequest(state, { req: 'destroyHighlight', status: 'fulfilled' })
      })
      .addCase(destroyHighlight.rejected, (state) => {
        setRequest(state, { req: 'destroyHighlight', status: 'rejected' })
      })
      .addCase(toggleHighlightStatus.pending, (state, action) => {
        setRequest(state, {
          status: 'pending',
          req: 'toggleHighlightStatus',
          meta: { id: action.meta.arg },
        })
      })
      .addCase(toggleHighlightStatus.fulfilled, (state, action) => {
        state.data = actions.updateOne(state.data, action.payload)
        setRequest(state, { req: 'toggleHighlightStatus', status: 'fulfilled', meta: {} })
      })
      .addCase(toggleHighlightStatus.rejected, (state) => {
        setRequest(state, { req: 'toggleHighlightStatus', status: 'rejected', meta: {} })
      })
  },
})

export const { setHighlight, setOpenModal } = slice.actions

export * from './actions'

export default slice.reducer
