export const MemberType = {
  HOLDER: 'titular',
  ACCREDITED: 'credenciado',

  options() {
    return [
      { label: 'Titular', value: this.HOLDER },
      { label: 'Credenciado', value: this.ACCREDITED },
    ]
  },

  findOption(value) {
    return this.options().find((option) => option.value === value)
  },
}
