import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from '../helpers'

export const handleModalState = createAction(
  'representatives/handleModalState',
  (isOpen = false, action = '', selected = null) => ({
    payload: { selected, modalState: { action, isOpen } },
  }),
)

export const fetchRepresentatives = createAsyncThunk(
  'representatives/fetchRepresentatives',
  async () => {
    const res = await santeApi.get('admin/representatives')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().representatives, 'fetchRepresentatives', 'fulfilled')
        ? forceFetch
        : true
    },
  },
)

export const storeRepresentative = createAsyncThunk(
  'representatives/storeRepresentative',
  async (data) => {
    const res = await santeApi.post('admin/representatives', data)
    return res.data
  },
)

export const toggleIsPropertyRep = createAsyncThunk(
  'representatives/toggleIsPropertyRep',
  async ({ id, isProperty }) => {
    const res = await santeApi.patch(`admin/representatives/${id}/toggle/${isProperty}`)
    return { id, changes: res.data }
  },
)

export const getRepresentative = createAsyncThunk(
  'representatives/getRepresentative',
  async (id) => {
    const res = await santeApi.get(`admin/representatives/${id}`)
    return res.data
  },
)

export const updateRepresentative = createAsyncThunk(
  'representatives/updateRepresentative',
  async ({ id, data }) => {
    const res = await santeApi.put(`admin/representatives/${id}`, data)
    return { id, changes: res.data }
  },
)

export const destroyRepresentative = createAsyncThunk(
  'representatives/destroyRepresentative',
  async (id) => {
    await santeApi.delete(`admin/representatives/${id}`)
    return { id }
  },
)
