import santeApi from 'src/services/sante-api'

/**
 * @typedef Specialty
 * @type {Object}
 * @property {number} id
 * @property {string} name
 * @property {string} slug
 */

/**
 * @param {Object} params
 * @param {number=} params.clc Clinic id
 * @returns {Promise<Specialty[]>}
 */
export async function fetchSpecialties(params = {}) {
  const res = await santeApi.get('admin/specialties', { params })

  return res.data
}

/**
 *
 * @param {Object} data
 * @param {String} data.name
 * @param {Number[]=} data.attendance_types
 * @returns {Promise<Specialty>}
 */
export async function createSpecialty(data) {
  const res = await santeApi.post('admin/specialties', data)
  return res.data
}

/**
 *
 * @param {number} id
 * @param {Object} data
 * @param {String} data.name
 * @returns {Promise<Specialty>}
 */
export async function updateSpecialty(id, data) {
  const res = await santeApi.put(`admin/specialties/${id}`, data)
  return res.data
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function deleteSpecialty(id) {
  await santeApi.delete(`admin/specialties/${id}`)
}

/**
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function toggleSpecialty(id) {
  await santeApi.post(`admin/specialties/${id}/toggle`)
}

/**
 * @param {number} specialtyId
 * @return {Promise<{ id: number, name: string }[]>}
 */
export async function getUnlinkedClinics(specialtyId) {
  const res = await santeApi.get(`admin/specialties/${specialtyId}/unlinked/clinics`)
  return res.data
}

/**
 * @param {number} specialtyId
 * @param {number} clinicId
 * @return {Promise<{ id: number, name: string }[]>}
 */
export async function getUnlinkedProfessionals(specialtyId, clinicId) {
  const res = await santeApi.get(
    `admin/specialties/${specialtyId}/unlinked/${clinicId}/professionals`,
  )
  return res.data
}
