import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions, checkRequest } from 'src/features/helpers'

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async () => {
    const res = await santeApi.get('admin/notifications')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().notifications, 'fetchNotifications', 'fulfilled')
        ? forceFetch
        : true
    },
  },
)

export const storeNotification = createAsyncThunk(
  'notifications/storeNotification',
  async (data, { getState }) => {
    let res = await santeApi.post('admin/notifications', data)

    try {
      if (data.send_after_save) {
        res = await santeApi.post(`admin/notifications/${res.data.id}/send`)
      }
    } catch (error) {
      console.error(error)
    }

    return actions.addOne(getState().notifications.data, res.data)
  },
)

export const updateNotification = createAsyncThunk(
  'notifications/updateNotification',
  async ({ id, data }, { getState }) => {
    const res = await santeApi.put(`admin/notifications/${id}`, data)
    return actions.updateOne(getState().notifications.data, res.data)
  },
)

export const destroyNotification = createAsyncThunk(
  'notifications/destroyNotification',
  async (id, { getState }) => {
    await santeApi.delete(`admin/notifications/${id}`)
    return actions.removeOne(getState().notifications.data, id)
  },
)

export const sendNotification = createAsyncThunk(
  'notifications/sendNotification',
  async (id, { getState }) => {
    const res = await santeApi.post(`admin/notifications/${id}/send`)
    return actions.updateOne(getState().notifications.data, res.data)
  },
)
