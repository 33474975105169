import { get } from 'lodash-es'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import santeApi, { config } from 'src/services/sante-api'

const handleSuccessEmailVerification = (response, user) => {
  if (response.data.code === 'already-verified') {
    const updatedUser = { ...user, email_verified_at: new Date().toISOString() }

    localStorage.setItem(config.localKeys.user, JSON.stringify(updatedUser))

    return { message: response.data.message, user: updatedUser }
  }

  return { message: response.data.message }
}
export const signOut = createAction('auth/signOut', () => {
  localStorage.removeItem(config.localKeys.user)
  localStorage.removeItem(config.localKeys.token)
  return { payload: null }
})

export const signIn = createAsyncThunk('auth/signIn', async (data) => {
  const res = await santeApi.post('admin/login', data)

  const { user, token } = res.data

  localStorage.setItem(config.localKeys.token, token)
  localStorage.setItem(config.localKeys.user, JSON.stringify(user))

  return user
})

export const changePassword = createAsyncThunk('auth/changePassword', async (data) => {
  await santeApi.post('admin/user/change-password', data)
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (data) => {
  const res = await santeApi.post('admin/forgot-password', {
    email: data.email,
    redirect_to: `${window.location.origin}/#/reset-password`,
  })

  return res.data
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data) => {
  const res = await santeApi.put('reset-password', data)
  return res.data
})

export const sendEmailVerificationLink = createAsyncThunk(
  'auth/sendEmailVerificationLink',
  async (email, { getState }) => {
    const res = await santeApi.post(
      'email/verification-notification',
      { email, redirect_to: `${window.location.origin}/#/verify-email` },
      { customToast: true },
    )

    return handleSuccessEmailVerification(res, getState().auth.user)
  },
)

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (token, { getState }) => {
  const res = await santeApi.get(`verify-email/${token}`, { customToast: true })
  return handleSuccessEmailVerification(res, getState().auth.user)
})

export const syncRoleAndPermissions = createAsyncThunk(
  'auth/syncRoleAbilities',
  async (forceSync = false, { getState }) => {
    const user = getState().auth.user

    const res = await santeApi.get(`admin/authorization/${user.id}`)

    const data = {
      ...user,
      role: res.data.role,
      permissions: res.data.permissions,
    }

    localStorage.setItem(config.localKeys.syncRolePermissions, 'true')
    localStorage.setItem(config.localKeys.user, JSON.stringify(data))

    return data
  },
  {
    condition: (forceSync, { getState }) => {
      const isAuth = getState().auth.isAuth

      const isSynchronized = localStorage.getItem(config.localKeys.syncRolePermissions) || 'false'

      return isAuth && (isSynchronized === 'false' || forceSync === true)
    },
  },
)

export const updateProfile = createAsyncThunk('auth/updateProfile', async (data, { getState }) => {
  const res = await santeApi.post('admin/user/update', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  const user = { ...get(getState().auth, 'user', {}), ...res.data.user }

  localStorage.setItem(config.localKeys.user, JSON.stringify(user))

  return user
})
