import { useMemo } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateService } from 'src/lib/sante-api/services'

import { FETCH_SERVICES_QUERY_KEY } from './useFetchServicesQuery'

/**
 * @param {import('src/types/sante-api').FetchServicesParams} params
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useUpdateServiceMutation(params, options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [FETCH_SERVICES_QUERY_KEY, params], [params])

  return useMutation({
    ...options,

    mutationFn: ({ id, ...data }) => updateService(id, data),

    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) =>
        old.map((oldItem) => (oldItem.id === item.id ? { ...oldItem, ...item } : oldItem)),
      )

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
}
