import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions } from 'src/features/helpers'

import { isValidDate } from 'src/utils/date'

export const fetchAppointments = createAsyncThunk(
  'appointments/fetch',
  async ({ filters = {}, cancelToken = undefined } = {}) => {
    const res = await santeApi.get('admin/appointments', { cancelToken, params: filters })

    return { data: res.data, filters }
  },
  {
    condition: ({ forceFetch = false, filters = {} } = {}, { getState }) => {
      const { data = [] } = getState().appointments

      if (data.length === 0 || forceFetch) return true

      if (!isValidDate(filters.start) || !isValidDate(filters.end)) return false

      return true
    },
  },
)

export const storeAppointment = createAsyncThunk('appointments/store', async (data) => {
  const res = await santeApi.post('admin/appointments', data)
  return res.data
})

export const getAppointment = createAsyncThunk('appointments/getAppointment', async (id) => {
  const res = await santeApi.get(`admin/appointments/${id}`)
  return res.data
})

export const updateAppointment = createAsyncThunk('appointments/update', async ({ id, data }) => {
  const res = await santeApi.put(`admin/appointments/${id}`, data)
  return res.data
})

export const destroyAppointment = createAsyncThunk(
  'appointments/destroy',
  async (id, { getState }) => {
    await santeApi.delete(`admin/appointments/${id}`)
    return actions.removeOne(getState().appointments.data, id)
  },
)

export const changeAppointmentStatus = createAsyncThunk(
  'appointments/changeAppointmentStatus',
  async ({ id, status }, { getState }) => {
    const res = await santeApi.patch(`admin/appointments/${id}/change-status`, { status })
    return actions.updateOne(getState().appointments.data, res.data)
  },
)

export const getAppointmentToPay = createAsyncThunk(
  'appointments/getAppointmentToPay',
  async (id) => {
    const res = await santeApi.get(`admin/appointments/${id}`)
    return res.data
  },
)

export const payAppointmentNow = createAsyncThunk(
  'appointments/payAppointmentNow',
  async ({ appointment, data }, { rejectWithValue }) => {
    try {
      const res = await santeApi.post(`admin/appointments/${appointment.id}/payments`, data, {
        customToast: true,
      })

      return {
        message: res.data.message,
        appointment: { ...appointment, payment: { ...res.data.data } },
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const changePaymentStatus = createAsyncThunk(
  'appointments/changePaymentStatus',
  async ({ appointment, status }, { getState }) => {
    const { id, payment } = appointment

    const res = await santeApi.patch(`admin/appointments/${id}/payments/${payment.id}`, { status })

    return actions.updateOne(getState().appointments.data, { id, payment: { ...res.data } })
  },
)

export const cancelOrReversePayment = createAsyncThunk(
  'appointment/cancelOrReversePayment',
  async ({ id, payment }, { getState }) => {
    const res = await santeApi.delete(`admin/appointments/${id}/payments/${payment.id}`)
    return actions.updateOne(getState().appointments.data, { id, payment: { ...res.data } })
  },
)
