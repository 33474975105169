import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import * as api from './actions'

const adapter = createEntityAdapter()

const initialState = {
  customerId: null,

  modalState: { action: '', isOpen: false },

  selected: null,

  requests: {},
}

const slice = createSlice({
  name: 'creditCards',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setCreditCards: adapter.setAll,

    addCreditCard: (state, action) => {
      adapter.addOne(state, action.payload)

      state.selected = action.payload
      state.modalState = initialState.modalState
    },

    editCreditCard: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload.data,
      })

      state.modalState = initialState.modalState
      state.selected = { ...state.selected, ...action.payload.data }
    },

    setModalState: (state, action) => {
      state.modalState = action.payload ?? initialState.modalState
    },

    setSelectedCard: (state, action) => {
      state.selected = action.payload ?? initialState.selected
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(api.handleCreditCardModal, (state, action) => {
        state.modalState = action.payload.modalState
        if (action.payload.creditCard) state.selected = action.payload.creditCard
      })
      .addCase(api.fetchCreditCards.pending, (state) => {
        setRequest(state, { req: 'fetchCreditCards', status: 'pending' })
      })
      .addCase(api.fetchCreditCards.fulfilled, (state, action) => {
        state.customerId = action.payload.customerId
        adapter.setAll(state, action.payload.creditCards)
        setRequest(state, { req: 'fetchCreditCards', status: 'fulfilled' })
      })
      .addCase(api.fetchCreditCards.rejected, (state) => {
        setRequest(state, { req: 'fetchCreditCards', status: 'rejected' })
      })
      .addCase(api.storeCreditCard.pending, (state) => {
        setRequest(state, { req: 'storeCreditCard', status: 'pending' })
      })
      .addCase(api.storeCreditCard.fulfilled, (state, action) => {
        adapter.addOne(state, action.payload)

        if (state.modalState.isOpen) state.modalState = initialState.modalState

        setRequest(state, { req: 'storeCreditCard', status: 'fulfilled' })
      })
      .addCase(api.storeCreditCard.rejected, (state) => {
        setRequest(state, { req: 'storeCreditCard', status: 'rejected' })
      })
      .addCase(api.updateCreditCard.pending, (state) => {
        setRequest(state, { req: 'updateCreditCard', status: 'pending' })
      })
      .addCase(api.updateCreditCard.fulfilled, (state, action) => {
        adapter.updateOne(state, action.payload)

        if (state.modalState.isOpen) state.modalState = initialState.modalState

        setRequest(state, { req: 'updateCreditCard', status: 'fulfilled' })
      })
      .addCase(api.updateCreditCard.rejected, (state) => {
        setRequest(state, { req: 'updateCreditCard', status: 'rejected' })
      })
      .addCase(api.destroyCreditCard.pending, (state) => {
        setRequest(state, { req: 'destroyCreditCard', status: 'pending' })
      })
      .addCase(api.destroyCreditCard.fulfilled, (state, action) => {
        adapter.removeOne(state, action.payload.id)
        state.modalState = initialState.modalState
        setRequest(state, { req: 'destroyCreditCard', status: 'fulfilled' })
      })
      .addCase(api.destroyCreditCard.rejected, (state) => {
        setRequest(state, { req: 'destroyCreditCard', status: 'rejected' })
      })
  },
})

export const { addCreditCard, editCreditCard, setCreditCards, setModalState, setSelectedCard } =
  slice.actions

export const creditCardSelectors = adapter.getSelectors((state) => state.creditCards)

export * from './actions'

export default slice.reducer
