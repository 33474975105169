import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentData: null,

  modalState: { action: null, isOpen: false },

  requests: {},
}

export const slice = createSlice({
  name: 'specialties',
  initialState,
  reducers: {
    setSpecialty: (state, action) => {
      state.currentData = action.payload
    },
    addSpecialty: (state, action) => {
      state.currentData = action.payload
      state.modalState = { action: 'create', isOpen: true }
    },
    editSpecialty: (state, action) => {
      state.currentData = action.payload
      state.modalState = { action: 'update', isOpen: true }
    },
    confirmSpecialtyDeletion: (state, action) => {
      state.currentData = action.payload
      state.modalState = { action: 'delete', isOpen: true }
    },
    resetDialogState: (state, action) => {
      state.modalState = initialState.modalState
      state.currentData = initialState.currentData
    },
  },
})

export const {
  addSpecialty,
  confirmSpecialtyDeletion,
  editSpecialty,
  resetDialogState,
  setSpecialty,
} = slice.actions

export default slice.reducer
