export const AppointmentStatus = {
  /**
   * Cancelado
   */
  CANCELED: 'canceled',
  /**
   * Aguardando analise
   */
  UNDER_ANALYSIS: 'under_analysis',
  /**
   * Agendado/Marcado
   */
  MARKED: 'marked',
  /**
   * Concluído
   */
  COMPLETED: 'completed',

  options() {
    return [
      { label: 'Aguardando analise', value: this.UNDER_ANALYSIS },
      { label: 'Agendado/Marcado', value: this.MARKED },
      { label: 'Concluído', value: this.COMPLETED },
      { label: 'Cancelado', value: this.CANCELED },
    ]
  },

  findOption(value) {
    return this.options().find((option) => option.value === value)
  },

  /**
   * Get color by status
   *
   * @param {string} status
   * @returns {string}
   */
  color(status) {
    const colors = {
      [this.MARKED]: 'primary',
      [this.CANCELED]: 'danger',
      [this.COMPLETED]: 'success',
      [this.UNDER_ANALYSIS]: 'warning',
    }

    if (!(status in colors)) return 'default'

    return colors[status]
  },
}
