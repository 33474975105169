import config from 'src/configs/sante-api'

export function getTokenFromStorage() {
  return localStorage.getItem(config.localKeys.token)
}

export function getUserFromStorage() {
  const data = localStorage.getItem(config.localKeys.user)
  return data ? JSON.parse(data) : null
}
