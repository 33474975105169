import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from 'src/features/helpers'

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async () => {
    const res = await santeApi.get('admin/users')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().users, 'fetchUsers', 'fulfilled') ? forceFetch : true
    },
  },
)

export const storeUser = createAsyncThunk('users/storeUser', async (data, { getState }) => {
  const res = await santeApi.post('admin/users', data)
  return res.data
})

export const showUser = createAsyncThunk('users/showUser', async (id) => {
  const res = await santeApi.get(`admin/users/${id}`)
  return res.data
})

export const updateUser = createAsyncThunk('users/updateUser', async ({ id, data }) => {
  const res = await santeApi.put(`admin/users/${id}`, data)
  return { id, ...res.data }
})

export const destroyUser = createAsyncThunk('users/destroyUser', async ({ id, force = false }) => {
  await santeApi.delete(`admin/users/${id}`, { params: { force: force ? 1 : 0 } })
  return id
})

export const changeUserStatus = createAsyncThunk('users/changeUserStatus', async ({ id, data }) => {
  const res = await santeApi.patch(`admin/users/${id}/change-status`, data)
  return { id, ...res.data }
})

export const changePassword = createAsyncThunk('users/changePassword', async ({ id, data }) => {
  await santeApi.patch(`admin/users/${id}/change-password`, data)
})

export const resetUserPermissions = createAsyncThunk(
  'authorization/resetUserPermissions',
  async (id) => {
    const res = await santeApi.delete(`admin/authorization/${id}`)
    return res.data
  },
)
