import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const routes = [
  {
    exact: true,
    path: '/login',
    name: 'Sign In',
    element: React.lazy(() => import('./views/auth/sign-in')),
  },
  {
    exact: true,
    path: '/logout',
    name: 'Sign Out',
    element: React.lazy(() => import('./views/auth/sign-out')),
  },
  {
    exact: true,
    path: '/forgot-password',
    name: 'Forgot Password',
    element: React.lazy(() => import('./views/auth/forgot-password')),
  },
  {
    exact: true,
    path: '/reset-password/:email/:token',
    name: 'Reset Password',
    element: React.lazy(() => import('./views/auth/reset-password')),
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    element: React.lazy(() => import('src/views/auth/verify-email')),
  },
  {
    exact: true,
    path: '/404',
    name: 'Page 404',
    element: React.lazy(() => import('./views/errors/page404')),
  },
  {
    exact: true,
    path: '/500',
    name: 'Page 500',
    element: React.lazy(() => import('./views/errors/page500')),
  },
  { exact: false, path: '*', name: 'Home', element: React.lazy(() => import('./layout')) },
]

class App extends Component {
  render() {
    return (
      <HashRouter>
        <ToastContainer autoClose={3000} />

        <Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                name={route.name}
                exact={route.exact}
                element={<route.element />}
              />
            ))}
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
