import { defaultTo, get } from 'lodash-es'

export function addModuleWithPermissions(state = {}, resData = {}) {
  const modules = Array.from(get(defaultTo(state, {}), 'modules', []))
  const currentPermissions = Array.from(get(defaultTo(state, {}), 'permissions', []))

  try {
    const { permissions = [], ...module } = defaultTo(resData, {})

    modules.push(module)
    modules.sort(({ nameA, nameB }) => (nameA > nameB ? 1 : nameA < nameB ? -1 : 0))

    permissions.forEach((permission) => {
      currentPermissions.push(permission)
    })
  } catch (error) {
    console.log(error)
  } finally {
    return { modules, permissions: currentPermissions }
  }
}

export function editModuleAndPermissions(state = {}, reqData = {}, resData = {}) {
  let currentModules = Array.from(get(defaultTo(state, {}), 'modules', []))
  let currentPermissions = Array.from(get(defaultTo(state, {}), 'permissions', []))

  try {
    const { permissions = [], ...module } = defaultTo(resData, {})
    const permissionsToRemove = get(defaultTo(reqData, {}), 'toRemove', [])

    const updatedPermissions = Array.from(permissions).reduce(
      (acc, item) => {
        let permissionIndex = acc.findIndex(({ id }) => id === item.id)

        // acc = [...acc, item]
        if (permissionIndex === -1) acc.push(item)
        else acc[permissionIndex] = { ...acc[permissionIndex], ...item }

        return acc
      },
      [...get(defaultTo(state, {}), 'permissions', [])],
    )

    currentModules = currentModules.map((item) => {
      return item.id === module.id ? { ...item, ...module } : item
    })

    currentPermissions = updatedPermissions.filter((item) => {
      return !permissionsToRemove.includes(item.id)
    })
  } catch (error) {
    console.log(error)
  } finally {
    return { modules: currentModules, permissions: currentPermissions }
  }
}

export function removeModuleWithPermissions(state, moduleId) {
  const modules = Array.from(get(defaultTo(state, {}), 'modules', []))

  let selectedModule = Array.from(get(defaultTo(state, {}), 'module', {}))
  let currentPermissions = Array.from(get(defaultTo(state, {}), 'permissions', []))

  try {
    const moduleIndex = modules.findIndex(({ id }) => id === moduleId)

    if (moduleIndex === -1) return

    const module = modules.splice(moduleIndex, 1)[0]

    selectedModule = modules[moduleIndex - 1]

    currentPermissions = currentPermissions.filter((item) => {
      return !item.name.includes(module.name)
    })
  } catch (error) {
    console.log(error)
  } finally {
    return { modules, module: selectedModule, permissions: currentPermissions }
  }
}
