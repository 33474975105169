import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from 'src/features/helpers'

export const fetchStatistics = createAsyncThunk(
  'statistics/fetchStatistics',
  async () => {
    const res = await santeApi.get('admin/statistics')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().statistics, 'fetchStatistics', 'fulfilled') ? forceFetch : true
    },
  },
)

export const getAppointmentsGroupedByCategory = createAsyncThunk(
  'statistics/getAppointmentsGroupedByCategory',
  async () => {
    const res = await santeApi.get('admin/statistics/appointments')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().statistics, 'getAppointmentsGroupedByCategory', 'fulfilled')
        ? forceFetch
        : true
    },
  },
)
