import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from 'src/features/helpers'

export const handleModalState = createAction(
  'modalities/handleModalState',
  (isOpen = false, action = '', selected = null) => ({
    payload: { selected, modalState: { action, isOpen } },
  }),
)

export const fetchModalities = createAsyncThunk(
  'modalities/fetch',
  async ({ forceFetch = false } = {}) => {
    const res = await santeApi.get('admin/modalities')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().modalities, 'fetchModalities', 'fulfilled') ? forceFetch : true
    },
  },
)

export const getModality = createAsyncThunk(
  'modalities/getModality',
  async ({ id, params }, { getState }) => {
    const res = await santeApi.get(`admin/modalities/${id}`, { params })
    return res.data
  },
)

export const storeModality = createAsyncThunk('modalities/store', async (data) => {
  const res = await santeApi.post('admin/modalities', data)
  return res.data
})

export const updateModality = createAsyncThunk('modalities/update', async ({ id, data }) => {
  const res = await santeApi.put(`admin/modalities/${id}`, data)
  return { id, changes: res.data }
})

export const destroyModality = createAsyncThunk('modalities/destroy', async (id) => {
  await santeApi.delete(`admin/modalities/${id}`)
  return { id }
})
