import santeApi from 'src/services/sante-api'

/**
 *
 * @param {import('src/types/sante-api/appointment').FetchParams} params
 * @return {Promise<import('src/types/sante-api/appointment').AppointmentMini[]>}
 */
export async function fetchAppointments(params = {}) {
  const res = await santeApi.get('admin/appointments', { params })
  return res.data
}

/**
 * @typedef CheckTimeAvailabilityResponse
 * @type {Object}
 * @property {string} message
 * @property {'available' | 'unavailable' | 'unavailable_day' | 'invalid_time'} status
 */

/**
 * @param {string} dateTimeTz
 * @param {number} clinicId
 * @returns {Promise<CheckTimeAvailabilityResponse>}
 * @example checkTimeAvailability('2023-07-17T08:30:00-03:00', 1)
 */
export async function checkTimeAvailability(dateTimeTz, clinicId) {
  const res = await santeApi.get(`admin/appointments/${dateTimeTz}/is-available-to/${clinicId}`)

  return res.data
}

export * from './needed-data'
