import { createSlice } from '@reduxjs/toolkit'

import moment from 'moment'

import {
  appointmentGuide,
  appointmentAnalytical,
  appointmentBillingPerProfessional,
  appointmentSynthetic,
  customerAnalytical,
  serviceListRegistered,
  serviceScheduled,
} from './actions'

const initialState = {
  loading: false,

  selectedReportType: null,

  period: {
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
  },

  showReport: {
    isOpen: false,
    pdf: { filename: '', src: '' },
  },
}

export const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      if (action.payload) {
        state.period = action.payload
      } else {
        state.period = initialState.period
      }
    },
    setSelectedReportType: (state, action) => {
      if (action.payload) {
        state.selectedReportType = action.payload
      } else {
        state.selectedReportType = initialState.selectedReportType
      }
    },
    setShowReport: (state, action) => {
      if (action.payload) {
        state.showReport = action.payload
      } else {
        state.showReport = initialState.showReport
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appointmentGuide.pending, (state) => {
        state.loading = true
      })
      .addCase(appointmentGuide.fulfilled, (state, action) => {
        state.loading = false
        state.showReport.isOpen = true
        state.showReport.pdf = action.payload
      })
      .addCase(appointmentGuide.rejected, (state, action) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(appointmentAnalytical.pending, (state) => {
        state.loading = true
      })
      .addCase(appointmentAnalytical.fulfilled, (state, action) => {
        state.loading = false
        state.showReport.isOpen = true
        state.showReport.pdf = action.payload
      })
      .addCase(appointmentAnalytical.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(appointmentBillingPerProfessional.pending, (state) => {
        state.loading = true
      })
      .addCase(appointmentBillingPerProfessional.fulfilled, (state, action) => {
        state.loading = false

        if (action.payload) {
          state.showReport.isOpen = true
          state.showReport.isOpen = true
          state.showReport.pdf = action.payload
        }
      })
      .addCase(appointmentBillingPerProfessional.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(appointmentSynthetic.pending, (state) => {
        state.loading = true
      })
      .addCase(appointmentSynthetic.fulfilled, (state, action) => {
        state.loading = false
        state.showReport.isOpen = true
        state.showReport.pdf = action.payload
      })
      .addCase(appointmentSynthetic.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(customerAnalytical.pending, (state) => {
        state.loading = true
      })
      .addCase(customerAnalytical.fulfilled, (state, action) => {
        state.loading = false

        if (action.payload) {
          state.showReport.isOpen = true
          state.showReport.isOpen = true
          state.showReport.pdf = action.payload
        }
      })
      .addCase(customerAnalytical.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(serviceListRegistered.pending, (state) => {
        state.loading = true
      })
      .addCase(serviceListRegistered.fulfilled, (state, action) => {
        state.loading = false
        state.showReport.isOpen = true
        state.showReport.pdf = action.payload
      })
      .addCase(serviceListRegistered.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
      .addCase(serviceScheduled.pending, (state) => {
        state.loading = true
      })
      .addCase(serviceScheduled.fulfilled, (state, action) => {
        state.loading = false
        state.showReport.isOpen = true
        state.showReport.pdf = action.payload
      })
      .addCase(serviceScheduled.rejected, (state) => {
        state.loading = false
        state.showReport = initialState.showReport
      })
  },
})

export const { setPeriod, setSelectedReportType, setShowReport } = slice.actions

export * from './actions'

export default slice.reducer
