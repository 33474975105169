export const GracePeriod = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',

  options() {
    return [
      { label: 'Dias', value: this.DAYS },
      { label: 'Meses', value: this.MONTHS },
      { label: 'Anos', value: this.YEARS },
    ]
  },

  findOption(value) {
    return this.options().find((option) => option.value === value)
  },

  defaultOption() {
    return this.findOption(this.DAYS)
  },

  getLowerLabel(value) {
    const options = {
      [this.DAYS]: 'dias',
      [this.MONTHS]: 'meses',
      [this.YEARS]: 'anos',
    }

    return value in options ? options[value] : options[this.DAYS]
  },
}
