import { createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import {
  destroyNotification,
  fetchNotifications,
  sendNotification,
  storeNotification,
  updateNotification,
} from './actions'

const initialState = {
  data: [],

  currentData: null,

  openModal: { action: '', isOpen: false },

  requests: {},
}

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.currentData = action.payload ?? initialState.currentData
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload ?? initialState.openModal
    },
    setSendingModal: (state, action) => {
      if (action.payload) {
        state.sendingModal = action.payload
      } else {
        state.sendingModal = initialState.sendingModal
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        setRequest(state, { req: 'fetchNotifications', status: 'pending' })
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'fetchNotifications', status: 'fulfilled' })
      })
      .addCase(fetchNotifications.rejected, (state) => {
        setRequest(state, { req: 'fetchNotifications', status: 'rejected' })
      })
      .addCase(storeNotification.pending, (state) => {
        setRequest(state, { req: 'storeNotification', status: 'pending' })
      })
      .addCase(storeNotification.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        setRequest(state, { req: 'storeNotification', status: 'fulfilled' })
      })
      .addCase(storeNotification.rejected, (state) => {
        state.openModal = initialState.openModal
        setRequest(state, { req: 'storeNotification', status: 'rejected' })
      })
      .addCase(updateNotification.pending, (state) => {
        setRequest(state, { req: 'updateNotification', status: 'pending' })
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        setRequest(state, { req: 'updateNotification', status: 'fulfilled' })
      })
      .addCase(updateNotification.rejected, (state) => {
        state.openModal = initialState.openModal
        setRequest(state, { req: 'updateNotification', status: 'rejected' })
      })
      .addCase(destroyNotification.pending, (state) => {
        setRequest(state, { req: 'destroyNotification', status: 'pending' })
      })
      .addCase(destroyNotification.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        setRequest(state, { req: 'destroyNotification', status: 'fulfilled' })
      })
      .addCase(destroyNotification.rejected, (state) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        setRequest(state, { req: 'destroyNotification', status: 'rejected' })
      })
      .addCase(sendNotification.pending, (state) => {
        setRequest(state, { req: 'sendNotification', status: 'pending' })
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        setRequest(state, { req: 'sendNotification', status: 'fulfilled' })
      })
      .addCase(sendNotification.rejected, (state) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        setRequest(state, { req: 'sendNotification', status: 'rejected' })
      })
  },
})

export const { setNotification, setOpenModal, setSendingModal } = slice.actions

export * from './actions'

export default slice.reducer
