import React, { createContext, useCallback, useContext, useEffect } from 'react'

import PropTypes from 'prop-types'
import { defaultTo } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'

import { setUser, syncRoleAndPermissions } from 'src/features/auth'
import { fetchModules, fetchPermissions, fetchRoles } from 'src/features/authorization'

import { isUserLoggedIn, getUserData } from 'src/utils/functions'

/**
 * @typedef AuthorizationContextProps
 * @type {Object}
 * @property {function(string|array):boolean|null} is
 * @property {function(string|array):boolean|null} can
 */

/** @type {AuthorizationContextProps} */
const initialContext = {
  is: (roles) => null,
  can: (permissions) => null,
}

const AuthorizationContext = createContext(initialContext)

export const AuthorizationProvider = ({ children }) => {
  const dispatch = useDispatch()

  const action = useSelector((s) => s.auth.action)

  const isAuthenticated = useSelector((s) => s.auth.isAuthenticated)

  /** @type {RoleProps} */
  const role = useSelector((s) => s.auth.role)

  /** @type {AbilityProps[]} */
  const permissions = useSelector((s) =>
    defaultTo(s.auth.permissions, []).map((permission) => ({
      ...permission,
      name: String(permission.name).replace('.', ':'),
    })),
  )

  useEffect(() => {
    if (isUserLoggedIn()) dispatch(setUser(getUserData()))
  }, [dispatch])

  useEffect(() => {
    if (action === 'reload-app') window.sessionStorage.removeItem('redirect')
  }, [action])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchRoles())
      dispatch(fetchModules())
      dispatch(fetchPermissions())
      dispatch(syncRoleAndPermissions())
    }
  }, [isAuthenticated, dispatch])

  const is = useCallback(
    (check) => {
      if (typeof check === 'string') check = check.split(',')
      return check.includes(role.name)
    },
    [role],
  )

  const can = useCallback(
    (check) => {
      if (check.length === 0) return false

      if (typeof check === 'string') check = check.split(',')

      const filtered = permissions.filter(({ name }) => {
        return check.includes(name)
      })

      return filtered.length === check.length
    },
    [permissions],
  )

  return (
    <AuthorizationContext.Provider value={{ can, is }}>{children}</AuthorizationContext.Provider>
  )
}

export const useAuthorization = () => useContext(AuthorizationContext)

AuthorizationProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
