import { createSlice } from '@reduxjs/toolkit'

import { actions, setRequest } from 'src/features/helpers'

import * as api from './actions'
import { setCustomer } from './actions/customer'

const initialState = {
  data: [],
  /** Todos os clientes sem paginação */
  allData: [],

  currentData: null,

  selectedAddress: null,
  selectedContact: null,

  queryParams: {
    q: null,
    trash: null,
    orderBy: 'name',
    page: 1,
    perPage: 10,
  },

  openModal: { action: '', isOpen: false },

  pagination: {
    current_page: 0,
    from: 0,
    last_page: 0,
    per_page: 10,
    to: 0,
    total: 0,
  },

  requests: {},
}

function handleActionFulfilled(state, action) {
  state.openModal = initialState.openModal
  state.currentData = action.payload.currentData
  if (action.payload?.data) state.data = action.payload.data
}

export const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.selectedAddress = action.payload
    },
    setContact: (state, action) => {
      state.selectedContact = action.payload
    },
    setCustomers: (state, action) => {
      state.data = action.payload
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload
    },
    setSearch: (state, action) => {
      state.queryParams.q = action.payload
    },
    setTrash: (state, action) => {
      state.queryParams.trash = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCustomer, (state, action) => {
        state.currentData = action.payload
      })
      .addCase(api.fetchCustomers.pending, (state) => {
        setRequest(state, { req: 'fetchCustomers', status: 'pending' })
      })
      .addCase(api.fetchCustomers.fulfilled, (state, action) => {
        const { data, pagination, queryParams } = action.payload

        state.data = data
        state.pagination = pagination
        state.queryParams = queryParams
        setRequest(state, { req: 'fetchCustomers', status: 'fulfilled' })
      })
      .addCase(api.fetchCustomers.rejected, (state) => {
        setRequest(state, { req: 'fetchCustomers', status: 'rejected' })
      })
      .addCase(api.storeCustomer.pending, (state) => {
        setRequest(state, { req: 'storeCustomer', status: 'pending' })
      })
      .addCase(api.storeCustomer.fulfilled, (state, action) => {
        state.data = action.payload
        state.selectedCreditCard = initialState.selectedCreditCard
        setRequest(state, { req: 'storeCustomer', status: 'fulfilled' })
      })
      .addCase(api.storeCustomer.rejected, (state) => {
        setRequest(state, { req: 'storeCustomer', status: 'rejected' })
      })
      .addCase(api.getCustomer.pending, (state) => {
        state.currentData = null
        setRequest(state, { req: 'getCustomer', status: 'pending' })
      })
      .addCase(api.getCustomer.fulfilled, (state, action) => {
        state.currentData = action.payload
        setRequest(state, { req: 'getCustomer', status: 'fulfilled' })
      })
      .addCase(api.getCustomer.rejected, (state) => {
        setRequest(state, { req: 'getCustomer', status: 'rejected' })
      })
      .addCase(api.updateCustomer.pending, (state) => {
        setRequest(state, { req: 'updateCustomer', status: 'pending' })
      })
      .addCase(api.updateCustomer.fulfilled, (state, action) => {
        state.currentData = { ...state.currentData, ...action.payload }

        state.data = actions.updateOne(state.data, action.payload)

        state.openModal = initialState.openModal

        setRequest(state, { req: 'updateCustomer', status: 'fulfilled' })
      })
      .addCase(api.updateCustomer.rejected, (state) => {
        setRequest(state, { req: 'updateCustomer', status: 'rejected' })
      })
      .addCase(api.destroyCustomer.pending, (state) => {
        setRequest(state, { req: 'destroyCustomer', status: 'pending' })
      })
      .addCase(api.destroyCustomer.fulfilled, (state, action) => {
        state.data = action.payload
        state.openModal = initialState.openModal
        setRequest(state, { req: 'destroyCustomer', status: 'fulfilled' })
      })
      .addCase(api.destroyCustomer.rejected, (state) => {
        setRequest(state, { req: 'destroyCustomer', status: 'rejected' })
      })
      .addCase(api.restoreCustomer.pending, (state) => {
        setRequest(state, { req: 'restoreCustomer', status: 'pending' })
      })
      .addCase(api.restoreCustomer.fulfilled, (state, action) => {
        if (action.payload) state.data = action.payload
        setRequest(state, { req: 'restoreCustomer', status: 'fulfilled' })
      })
      .addCase(api.toggleCustomer.pending, (state) => {
        setRequest(state, { req: 'toggleCustomer', status: 'pending' })
      })
      .addCase(api.toggleCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentData = action.payload
          state.data = actions.updateOne(state.data, action.payload)
        }

        setRequest(state, { req: 'toggleCustomer', status: 'fulfilled' })
      })
      .addCase(api.storeAddress.pending, (state) => {
        setRequest(state, { req: 'storeAddress', status: 'pending' })
      })
      .addCase(api.storeAddress.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'storeAddress', status: 'fulfilled' })
      })
      .addCase(api.storeAddress.rejected, (state) => {
        setRequest(state, { req: 'storeAddress', status: 'rejected' })
      })
      .addCase(api.updateAddress.pending, (state) => {
        setRequest(state, { req: 'updateAddress', status: 'pending' })
      })
      .addCase(api.updateAddress.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'updateAddress', status: 'fulfilled' })
      })
      .addCase(api.updateAddress.rejected, (state) => {
        setRequest(state, { req: 'updateAddress', status: 'rejected' })
      })
      .addCase(api.destroyAddress.pending, (state) => {
        setRequest(state, { req: 'destroyAddress', status: 'pending' })
      })
      .addCase(api.destroyAddress.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'destroyAddress', status: 'fulfilled' })
      })
      .addCase(api.destroyAddress.rejected, (state) => {
        setRequest(state, { req: 'destroyAddress', status: 'rejected' })
      })
      .addCase(api.getAppointments.pending, (state) => {
        setRequest(state, { req: 'getAppointments', status: 'pending' })
      })
      .addCase(api.getAppointments.fulfilled, (state, action) => {
        setRequest(state, { req: 'getAppointments', status: 'fulfilled' })
      })
      .addCase(api.getAppointments.rejected, (state) => {
        setRequest(state, { req: 'getAppointments', status: 'rejected' })
      })
      .addCase(api.storeContact.pending, (state) => {
        setRequest(state, { req: 'storeContact', status: 'pending' })
      })
      .addCase(api.storeContact.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'storeContact', status: 'fulfilled' })
      })
      .addCase(api.storeContact.rejected, (state) => {
        setRequest(state, { req: 'storeContact', status: 'rejected' })
      })
      .addCase(api.updateContact.pending, (state) => {
        setRequest(state, { req: 'updateContact', status: 'pending' })
      })
      .addCase(api.updateContact.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'updateContact', status: 'fulfilled' })
      })
      .addCase(api.updateContact.rejected, (state) => {
        setRequest(state, { req: 'updateContact', status: 'rejected' })
      })
      .addCase(api.destroyContact.pending, (state) => {
        setRequest(state, { req: 'destroyContact', status: 'pending' })
      })
      .addCase(api.destroyContact.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'destroyContact', status: 'fulfilled' })
      })
      .addCase(api.destroyContact.rejected, (state) => {
        setRequest(state, { req: 'destroyContact', status: 'rejected' })
      })
      .addCase(api.getAllCustomers.pending, (state) => {
        setRequest(state, { req: 'getAllCustomers', status: 'pending' })
      })
      .addCase(api.getAllCustomers.fulfilled, (state, action) => {
        state.allData = action.payload
        setRequest(state, { req: 'getAllCustomers', status: 'fulfilled' })
      })
      .addCase(api.getAllCustomers.rejected, (state) => {
        setRequest(state, { req: 'getAllCustomers', status: 'rejected' })
      })
      .addCase(api.migrateCustomer.pending, (state) => {
        setRequest(state, { req: 'migrateCustomer', status: 'pending' })
      })
      .addCase(api.migrateCustomer.fulfilled, (state, action) => {
        handleActionFulfilled(state, action)
        setRequest(state, { req: 'migrateCustomer', status: 'fulfilled' })
      })
      .addCase(api.migrateCustomer.rejected, (state) => {
        setRequest(state, { req: 'migrateCustomer', status: 'rejected' })
      })
  },
})

export const { setAddress, setContact, setCustomers, setOpenModal, setSearch, setTrash } =
  slice.actions

export { setCustomer }

export * from './useGetCustomerByIdQuery'
export * from './useToggleCustomerStatusMutation'

export default slice.reducer
