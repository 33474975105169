import { createSlice } from '@reduxjs/toolkit'

import { setRequest } from 'src/features/helpers'

import { destroyCallScheduling, fetchCallSchedules, updateCallScheduling } from './actions'

const initialState = {
  data: [],

  requests: {},
}

export const slice = createSlice({
  name: 'callSchedules',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCallSchedules.pending, (state) => {
        setRequest(state, { req: 'fetchCallSchedules', status: 'pending' })
      })
      .addCase(fetchCallSchedules.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'fetchCallSchedules', status: 'fulfilled' })
      })
      .addCase(fetchCallSchedules.rejected, (state) => {
        setRequest(state, { req: 'fetchCallSchedules', status: 'rejected' })
      })
      .addCase(updateCallScheduling.pending, (state, action) => {
        setRequest(state, { req: 'updateCallScheduling', status: 'pending' })

        state.requests.updateCallScheduling = {
          ...state.requests.updateCallScheduling,
          meta: action.meta.arg,
        }
      })
      .addCase(updateCallScheduling.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'updateCallScheduling', status: 'fulfilled' })
      })
      .addCase(updateCallScheduling.rejected, (state) => {
        setRequest(state, { req: 'updateCallScheduling', status: 'rejected' })
      })
      .addCase(destroyCallScheduling.pending, (state) => {
        setRequest(state, { req: 'destroyCallScheduling', status: 'pending' })
      })
      .addCase(destroyCallScheduling.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'destroyCallScheduling', status: 'fulfilled' })
      })
      .addCase(destroyCallScheduling.rejected, (state) => {
        setRequest(state, { req: 'destroyCallScheduling', status: 'rejected' })
      })
  },
})

export * from './actions'

export default slice.reducer
