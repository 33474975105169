import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateSpecialty } from 'src/lib/sante-api/specialties'

import { FETCH_SPECIALTIES_QUERY_KEY } from './useFetchSpecialtiesQuery'

/**
 *
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useUpdateSpecialtyMutation(options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [FETCH_SPECIALTIES_QUERY_KEY, {}], [])

  return useMutation({
    ...options,

    mutationFn: ({ id, ...data }) => updateSpecialty(id, data),

    onMutate: async (updatedItem) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) =>
        (old ?? []).map((item) =>
          item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
        ),
      )

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
    },
  })
}
