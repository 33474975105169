import { get } from 'lodash-es'

export function checkRequest(state, req, status) {
  return get(state, `requests.${req}.status`) === status
}

export function setRequest(state, { req, status = 'pending', message = '', meta = {} }) {
  state.requests[req] = {
    ...get(state.requests, req, {}),
    active: status === 'pending',
    status,
    message,
    meta,
  }
}

export function isProcessing(state, reqName, resourceId) {
  const req = get(state, `requests.${reqName}`, null)
  return req ? req?.active && req?.meta?.id === resourceId : false
}

export function checkActiveRequests(state, requests = []) {
  return Object.keys(state?.requests ?? {})
    .filter((req) => {
      return get(state, `requests.${req}.active`) ?? false
    })
    .some((req) => requests.includes(req))
}

export const actions = {
  addOne: (data = [], item = {}) => [...data, item],

  removeOne: (data = [], itemId) => data.filter((obj) => obj.id !== itemId),

  updateOne: (data = [], item = {}) => {
    return data.map((obj) => (obj.id === item.id ? { ...obj, ...item } : obj))
  },

  addItemToSubList: function ({ state, subList, item }) {
    const currentDataUpdated = {
      ...state.currentData,
      [subList]: this.addOne(state.currentData[subList], item),
    }

    return {
      currentData: currentDataUpdated,
      data: this.updateOne(state.data, currentDataUpdated),
    }
  },

  removeItemFromSubList: function ({ state, subList, itemId }) {
    const currentDataUpdated = {
      ...state.currentData,
      [subList]: this.removeOne(state.currentData[subList], itemId),
    }

    return {
      currentData: currentDataUpdated,
      data: this.updateOne(state.data, currentDataUpdated),
    }
  },

  updateItemInSubList: function ({ state, subList, item }) {
    const currentDataUpdated = {
      ...state.currentData,
      [subList]: this.updateOne(state.currentData[subList], item),
    }

    return {
      currentData: currentDataUpdated,
      data: this.updateOne(state.data, currentDataUpdated),
    }
  },
}
