import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { actions, checkRequest } from 'src/features/helpers'

export const setCustomer = createAction('customers/setCustomer', (payload) => ({ payload }))

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (params = {}, { getState }) => {
    const queryParams = {
      ...getState().customers.queryParams,
      ...params,
    }

    const res = await santeApi.get('admin/customers', {
      params: queryParams,
    })

    const { data, ...pagination } = res.data

    return {
      data,
      pagination,
      queryParams: {
        ...queryParams,
        page: pagination.current_page,
        perPage: pagination.per_page,
      },
    }
  },
)

export const storeCustomer = createAsyncThunk(
  'customers/storeCustomer',
  async (data, { getState }) => {
    const res = await santeApi.post('admin/customers', data, {
      headers: { 'content-type': 'multipart/form-data' },
    })

    return actions.addOne(getState().customers.data, res.data)
  },
)

export const getCustomer = createAsyncThunk(
  'customers/getCustomer',
  async (id) => {
    const res = await santeApi.get(`admin/customers/${id}`)
    return res.data
  },
  {
    condition: (id, { getState }) => {
      return parseInt(id) !== getState().customers.currentData?.id
    },
  },
)

export const updateCustomer = createAsyncThunk('customers/updateCustomer', async ({ id, data }) => {
  const res = await santeApi.put(`admin/customers/${id}`, data)
  return res.data
})

export const destroyCustomer = createAsyncThunk(
  'customers/destroyCustomer',
  async ({ id, force = false }, { getState }) => {
    await santeApi.delete(`admin/customers/${id}`, { params: { force: force ? 1 : 0 } })
    return actions.removeOne(getState().customers.data, id)
  },
)

export const restoreCustomer = createAsyncThunk(
  'customers/restoreCustomer',
  async (id, { getState }) => {
    try {
      await santeApi.post(`admin/customers/${id}/restore`)
      return actions.removeOne(getState().customers.data, id)
    } catch (error) {
      return null
    }
  },
)

export const toggleCustomer = createAsyncThunk(
  'customers/toggleCustomer',
  async (id, { getState }) => {
    try {
      const res = await santeApi.patch(`admin/customers/${id}/toggle-active`)
      return { ...getState().customers.currentData, ...res.data }
    } catch (error) {
      return null
    }
  },
)

export const getAppointments = createAsyncThunk('customers/getAppointments', async (customerId) => {
  const res = await santeApi.get(`admin/customers/${customerId}/appointments`)
  return res.data
})

export const getAllCustomers = createAsyncThunk(
  'customers/getAllCustomers',
  async ({ forceFetch = false } = {}) => {
    const res = await santeApi.get('admin/customers', {
      params: { orderBy: 'name', without: 'paginate' },
    })
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().customers, 'getAllCustomers', 'fulfilled') ? forceFetch : true
    },
  },
)

export const migrateCustomer = createAsyncThunk(
  'customers/migrateCustomer',
  async ({ id, data }) => {
    const res = await santeApi.put(`admin/customers/${id}`, data.customer)

    await santeApi.post(`admin/customers/${id}/migrate`, data.contract)

    return { currentData: res.data }
  },
)
