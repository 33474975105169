import santeApi from 'src/services/sante-api'

/**
 * @typedef TStateItem
 * @type {Object}
 * @property {string} label
 * @property {number} value
 * @property {string} initials
 */

/**
 * @typedef TCityItem
 * @type {Object}
 * @property {string} label
 * @property {number} value
 * @property {number} state_id
 */

/**
 * @typedef TClinicItem
 * @type {Object}
 * @property {string} label
 * @property {number} value
 * @property {number} city_id
 * @property {number} address_id
 */

/**
 * @typedef TProfessionalItem
 * @type {Object}
 * @property {string} label
 * @property {number} value
 * @property {number[]} cities
 * @property {number[]} clinics
 * @property {number[]} specialties
 */

/**
 * @typedef TSpecialtyItem
 * @type {Object}
 * @property {number} id
 * @property {string} name
 * @property {number[]} cities
 * @property {number[]} clinics
 * @property {{ [x: string]: number }} specialties
 * @property {{ [x: string]: number }} prices_by_clinic
 * @property {{ [x: string]: number }} prices_by_professional
 */

/**
 * @typedef TGroups
 * @type {Object}
 * @property {TStateItem[]} states
 * @property {TCityItem[]} cities
 * @property {TClinicItem[]} clinics
 * @property {TSpecialtyItem[]} specialties
 * @property {TProfessionalItem[]} professionals
 */

/**
 *
 * @param {number=} attendanceTypeId
 * @returns {Promise<TGroups>}
 */
export async function fetchNeededToAppointment(attendanceTypeId) {
  const res = await santeApi.get('admin/appointments/needed-data', {
    params: { att: attendanceTypeId },
  })

  return res.data
}
