import { createSlice } from '@reduxjs/toolkit'

import { professionalCategoriesApi } from './api'

const initialState = {
  selected: null,
  dialogState: { action: '', isOpen: false },
}

export const slice = createSlice({
  name: 'professionalCategories',
  initialState,
  reducers: {
    addProfessionalCategory: (state) => {
      state.selected = initialState.selected
      state.dialogState = { action: 'create', isOpen: true }
    },
    editProfessionalCategory: (state, action) => {
      state.selected = action.payload
      state.dialogState = { action: 'update', isOpen: true }
    },
    closeProfessionalCategoryDialog: (state) => {
      state.selected = initialState.selected
      state.dialogState = initialState.dialogState
    },
    confirmProfessionalCategoryDeletion: (state, action) => {
      state.selected = action.payload
      state.dialogState = { action: 'delete', isOpen: true }
    },
  },
})

export const {
  addProfessionalCategory,
  editProfessionalCategory,
  closeProfessionalCategoryDialog,
  confirmProfessionalCategoryDeletion,
} = slice.actions

export const {
  useGetProfessionalCategoriesQuery,
  useCreateProfessionalCategoryMutation,
  useUpdateProfessionalCategoryMutation,
  useDeleteProfessionalCategoryMutation,
} = professionalCategoriesApi

export default slice.reducer
