import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteSpecialty } from 'src/lib/sante-api/specialties'

import { FETCH_SPECIALTIES_QUERY_KEY } from './useFetchSpecialtiesQuery'

/**
 *
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export function useDeleteSpecialtyMutation(options = {}) {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [FETCH_SPECIALTIES_QUERY_KEY, {}], [])

  return useMutation({
    ...(options ?? {}),

    mutationFn: deleteSpecialty,

    onMutate: async (specialtyId) => {
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) =>
        (old ?? []).filter((item) => item.id !== specialtyId),
      )

      return { previous }
    },

    onError: (err, variables, context) => {
      if (context?.previous) queryClient.setQueryData(queryKey, context.previous)

      if (options?.onError) options.onError(err, variables, context)
    },

    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: queryKey })

      if (options?.onSettled) options.onSettled(data, error, variables, context)
    },
  })
}
