import { useQuery } from '@tanstack/react-query'

import { checkIfDayAlreadyExists } from 'src/lib/sante-api/attendance-schedules'

/**
 * @param {Object} params
 * @param {'clinic'|'professional'} params.entity
 * @param {number=} params.entityId
 * @param {string} params.day
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useDayAlreadyExists({ day, entity, entityId }, options) {
  return useQuery({
    enabled: !!entityId,
    ...options,
    staleTime: 1000 * 30, // 30 seconds
    queryKey: ['day-already-exists', entity, entityId, day],
    queryFn: () => checkIfDayAlreadyExists(entity, entityId, day),
  })
}
