import moment from 'moment'
import { get } from 'lodash-es'
import jwPaginate from 'jw-paginate'

import santeApiConfig from 'src/configs/sante-api'

import CITIES from 'src/assets/data/cities.json'
import STATES from 'src/assets/data/states.json'

import { GracePeriod, MaritalStatus } from 'src/lib/sante-api/constants'

export function isUserLoggedIn() {
  const userData = localStorage.getItem(santeApiConfig.localKeys.user)
  const tokenData = localStorage.getItem(santeApiConfig.localKeys.token)
  return !!(tokenData && userData)
}

export function getUserData() {
  return JSON.parse(localStorage.getItem(santeApiConfig.localKeys.user))
}

export function numberFormat(value, locale = 'pt-BR', style = 'currency', currency = 'BRL') {
  return new Intl.NumberFormat(locale, { style, currency }).format(value)
}

export function transGraceIn(data) {
  return GracePeriod.getLowerLabel(data?.grace_in)
}

export function formatDate(date, format = 'DD/MM/YYYY') {
  if (!date) return 'Não informado'
  return moment(date).format(format)
}

export function getMaritalStatus(data) {
  const maritalStatus = MaritalStatus.findOption(data.marital_status)

  return get(maritalStatus, 'label', 'Não informado')
}

/**
 *
 * @param {Array} data
 * @param {string} labelKey
 * @param {'id'|'*'} valueKey
 * @returns {Array<{label: string, value: any}>}
 */
export function makeSelectOptions(data, valueKey = 'id', labelKey = 'name') {
  return (data || []).map((item) => ({
    label: item[labelKey],
    value: valueKey !== '*' ? item[valueKey] : item,
  }))
}

/**
 *
 * @param {number|string} stateId
 * @returns {{id: number, name: string, initials: string}|null}
 */
export function findStateById(stateId) {
  return Array.from(STATES).find(({ id }) => id === stateId)
}

export function getStateAndCity(stateId, cityId) {
  try {
    if (!stateId || !cityId) return { state: null, city: null }

    const state = findStateById(stateId)

    const { cities } = Array.from(CITIES).find(({ value }) => value === stateId)

    const city = cities.find(({ value }) => value === cityId)

    return { city, state }
  } catch (error) {
    return { city: null, state: null }
  }
}

/**
 *
 * @param {Array} items
 * @param {Number|0} currentPage
 * @param {Number|10} pageSize
 * @returns {{ data: Array, pagination: Object }}
 */
export const paginate = (items, currentPage = 0, pageSize = 10) => {
  const pagination = jwPaginate(items.length, currentPage, pageSize)

  return {
    pagination,
    data: items.filter((item, idx) => idx >= pagination.startIndex && idx <= pagination.endIndex),
  }
}

/**
 *
 * @param {Array} items
 * @param {Number|0} currentPage
 * @param {Number|10} pageSize
 * @returns {{ data: Array<any>, totalItems: number; currentPage: number; pageSize: number; totalPages: number; startPage: number; endPage: number; startIndex: number; endIndex: number; pages: number[]; }}
 */
export const paginateV2 = (items, currentPage = 0, pageSize = 10) => {
  const pagination = jwPaginate(items.length, currentPage, pageSize)

  return {
    ...pagination,
    data: items.filter((item, idx) => idx >= pagination.startIndex && idx <= pagination.endIndex),
  }
}

export function getCardFlag(cardNumber) {
  const flags = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/, // Visa
    MasterCard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{14}$/, // MasterCard
    Amex: /^3[47][0-9]{13}/, // American Express
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/, // Elo
    Aura: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/, // Diners
    JCB: /^6(?:011|5[0-9]{2})[0-9]{12}/, // Discover
    Diners: /^(?:2131|1800|35\d{3})\d{11}/, // JCB
    Discover: /^(5078\d{2})(\d{2})(\d{11})$/, // Aura
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/, // Hipercard
  }

  return (
    Object.keys(flags).find((flag) => flags[flag].test(cardNumber.replace(/[^0-9]+/g, ''))) ?? ''
  )
}

/**
 * Round with precision
 *
 * @link https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Math/round#m%C3%A9todo_de_arredondamento_php
 */
export function roundWithPrecision(value, precision = 2) {
  const factor = Math.pow(10, precision)
  const tempNumber = value * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}
