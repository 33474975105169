export function setUserCallback(state, action) {
  state.user = action.payload

  if ('role' in action.payload) {
    state.role = action.payload.role
  }

  if ('permissions' in action.payload) {
    state.permissions = action.payload.permissions
  }

  state.loading = false
  state.isAuthenticated = action.payload ? true : false

  return state
}
