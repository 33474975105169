import axios from 'axios'
import { toast } from 'react-toastify'
import { get, has, isArray, isEmpty, isObject } from 'lodash-es'

import apiConfig from 'src/configs/sante-api'

/**
 * @typedef SanteApiConfig
 * @type {Object}
 * @property {string} baseURL
 * @property {string} tokenType
 * @property {Object} localKeys
 * @property {string} localKeys.user
 * @property {string} localKeys.token
 * @property {Object} endpoints
 * @property {string} endpoints.logout
 * @property {string} endpoints.refresh
 */

class SanteApi {
  /** @type {import('axios').AxiosInstance} */
  api

  /** @type {string} */
  #tokenType

  #endpoints = { logout: '', refresh: '' }

  #localKeys = { user: '', token: '' }

  /**
   *
   * @param {SanteApiConfig} santeConfig
   */
  constructor(santeConfig) {
    this.#tokenType = santeConfig.tokenType
    this.#endpoints = santeConfig.endpoints
    this.#localKeys = santeConfig.localKeys

    this.api = axios.create({ baseURL: santeConfig.baseURL, withCredentials: true })

    this.api.interceptors.request.use(
      (config) => this.handleRequestFulfilled(config),
      (error) => this.handleRequestRejected(error),
    )

    this.api.interceptors.response.use(
      (response) => this.handleResponseFulfilled(response),
      (error) => this.handleResponseRejected(error),
    )
  }

  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  handleRequestFulfilled(config) {
    const token = this.getToken()

    if (!has(config, 'headers.Accept')) {
      config.headers.Accept = 'application/json; charset=utf-8'
    }

    if (token) config.headers.Authorization = `${this.#tokenType} ${token}`

    return config
  }

  /**
   * @param {import('axios').AxiosError} error
   */
  handleRequestRejected(error) {
    return Promise.reject(error)
  }

  /**
   * @param {import('axios').AxiosResponse} response
   */
  handleResponseFulfilled(response) {
    if (!get(response, 'config.customToast', false) && has(response, 'data.message')) {
      toast.success(response.data.message)

      if ('data' in response.data) return { ...response, data: response.data.data }
    }

    return response
  }

  /**
   * @param {import('axios').AxiosError} error
   */
  handleResponseRejected(error) {
    if (error.response) {
      const { data, status } = error.response

      const isInvalidSession = [
        'Unauthenticated.',
        'Token has expired',
        'The token has been blacklisted',
        'Token Signature could not be verified',
      ].some((msg) => msg.includes(data?.message ?? ''))

      if (status === 401 && isInvalidSession) {
        localStorage.clear()
        global.location.reload()
        return
      }

      if (!get(error, 'config.customToast', false)) this.showToasts(data, status)

      const value = { message: data.message, status, details: get(data, 'errors', []) }

      if (isEmpty(value.details)) delete value.details

      return Promise.reject(value)
    } else if (error.request) {
      console.error(error.request)
    } else {
      console.error('Error Config', error.config)
      console.error('Error Message', error.message)
    }

    return Promise.reject({ message: 'Request error', status: 500, details: error })
  }

  showToasts(data, status) {
    const type = status === 500 ? 'error' : 'warning'

    if (has(data, 'errors') && isArray(data.errors)) {
      data.errors.forEach((error) => toast[type](error))
    } else if (has(data, 'errors') && isObject(data.errors)) {
      Object.keys(data.errors)
        .reduce((errors, field) => {
          errors = [...data.errors[field]]
          return errors
        }, [])
        .forEach((error) => toast[type](error))
    } else if (has(data, 'message')) {
      toast[type](get(data, 'message', 'Algo deu errado.'))
    }
  }

  getToken() {
    return localStorage.getItem(this.#localKeys.token)
  }

  setToken(token) {
    localStorage.setItem(this.#localKeys.token, token)
  }

  async singOut() {
    try {
      await this.api.delete(this.#endpoints.logout)

      localStorage.removeItem(this.#localKeys.user)
      localStorage.removeItem(this.#localKeys.token)
    } catch (error) {
      console.log(error)
    }
  }
}

export const config = { ...apiConfig }

export default new SanteApi({
  baseURL: apiConfig.apiURL,
  tokenType: apiConfig.tokenType,
  localKeys: {
    user: apiConfig.localKeys.user,
    token: apiConfig.localKeys.token,
  },
  endpoints: {
    logout: apiConfig.endpoints.session.logout,
    refresh: apiConfig.endpoints.session.refresh,
  },
}).api
