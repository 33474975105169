import { useQuery } from '@tanstack/react-query'

import { fetchAvailableTimes } from 'src/lib/sante-api/attendance-schedules'

export const FETCH_AVAILABLE_TIMES_QUERY_KEY = 'fetch-available-times'

/**
 * @param {'clc'|'pro'} entity
 * @param {number=} entityId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export function useFetchAvailableTimesQuery(entity, entityId, options = {}) {
  return useQuery({
    enabled: !!entityId,
    staleTime: 1000 * 60, // 1 minute
    ...options,
    queryKey: [FETCH_AVAILABLE_TIMES_QUERY_KEY, entity, entityId],
    queryFn: () => fetchAvailableTimes({ [entity]: entityId }),
  })
}
