import { createSlice } from '@reduxjs/toolkit'

import { actions, setRequest } from 'src/features/helpers'

import {
  destroyPartner,
  fetchPartners,
  storePartner,
  togglePartnerStatus,
  updatePartner,
} from './actions'

const initialState = {
  data: [],

  currentData: null,

  openModal: { action: '', isOpen: false },

  requests: {},
}

export const slice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload ? action.payload : initialState.openModal
    },
    setPartner: (state, action) => {
      state.currentData = action.payload ?? initialState.currentData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartners.pending, (state) => {
        setRequest(state, { req: 'fetchPartners', status: 'pending' })
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.data = action.payload
        setRequest(state, { req: 'fetchPartners', status: 'fulfilled' })
      })
      .addCase(fetchPartners.rejected, (state) => {
        setRequest(state, { req: 'fetchPartners', status: 'rejected' })
      })
      .addCase(storePartner.pending, (state) => {
        setRequest(state, { req: 'storePartner', status: 'pending' })
      })
      .addCase(storePartner.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.data = actions.addOne(state.data, action.payload)
        setRequest(state, { req: 'storePartner', status: 'fulfilled' })
      })
      .addCase(storePartner.rejected, (state) => {
        setRequest(state, { req: 'storePartner', status: 'rejected' })
      })
      .addCase(updatePartner.pending, (state) => {
        setRequest(state, { req: 'updatePartner', status: 'pending' })
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        state.data = actions.updateOne(state.data, action.payload)
        setRequest(state, { req: 'updatePartner', status: 'fulfilled' })
      })
      .addCase(updatePartner.rejected, (state) => {
        setRequest(state, { req: 'updatePartner', status: 'rejected' })
      })
      .addCase(destroyPartner.pending, (state) => {
        setRequest(state, { req: 'destroyPartner', status: 'pending' })
      })
      .addCase(destroyPartner.fulfilled, (state, action) => {
        state.openModal = initialState.openModal
        state.currentData = initialState.currentData
        state.data = actions.removeOne(state.data, action.payload.id)
        setRequest(state, { req: 'destroyPartner', status: 'fulfilled' })
      })
      .addCase(destroyPartner.rejected, (state) => {
        setRequest(state, { req: 'destroyPartner', status: 'rejected' })
      })
      .addCase(togglePartnerStatus.pending, (state, action) => {
        setRequest(state, {
          status: 'pending',
          req: 'togglePartnerStatus',
          meta: { id: action.meta.arg },
        })
      })
      .addCase(togglePartnerStatus.fulfilled, (state, action) => {
        state.data = actions.updateOne(state.data, action.payload)
        setRequest(state, { req: 'togglePartnerStatus', status: 'fulfilled', meta: {} })
      })
      .addCase(togglePartnerStatus.rejected, (state) => {
        setRequest(state, { req: 'togglePartnerStatus', status: 'rejected', meta: {} })
      })
  },
})

export const { setOpenModal, setPartner } = slice.actions

export * from './actions'

export default slice.reducer
