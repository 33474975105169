import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter()

const initialState = adapter.getInitialState({
  selected: null,

  selectedSubtypes: [],

  dialogState: { action: '', isOpen: false, entity: null },
})

const slice = createSlice({
  name: 'attendanceTypes',
  initialState,
  reducers: {
    setAttendanceType: (state, action) => {
      state.selected = action.payload
      state.selectedSubtypes = action.payload?.subtypes ?? []
    },
    setAttendanceSubtypes: (state, action) => {
      state.selectedSubtypes = action.payload
    },
    addNewAttendanceType: (state) => {
      state.selected = initialState.selected
      state.dialogState = { isOpen: true, action: 'create/item', entity: null }
    },
    editAttendanceType: (state, action) => {
      state.selected = action.payload
      state.dialogState = { isOpen: true, action: 'update/item', entity: null }
    },
    confirmAttendanceTypeDeletion: (state, action) => {
      state.dialogState = { isOpen: true, action: 'delete/item', entity: action.payload }
    },
    addNewAttendanceSubtype: (state) => {
      state.selectedSubtypes = initialState.selectedSubtypes
      state.dialogState = { isOpen: true, action: 'create/subitem', entity: null }
    },
    editAttendanceSubtypes: (state, action) => {
      if (!action.payload) {
        state.dialogState = { isOpen: true, action: 'update/many-subitems', entity: null }
      } else {
        state.dialogState = {
          isOpen: true,
          entity: action.payload,
          action: 'udpate/one-subitem',
        }
      }
    },
    confirmAttendanceSubtypeDeletion: (state, action) => {
      state.dialogState = { isOpen: true, action: 'delete/subitem', entity: action.payload }
    },
    closeAttendanceTypesDialog: (state) => {
      state.selected = initialState.selected
      state.dialogState = initialState.dialogState
      state.selectedSubtypes = initialState.selectedSubtypes
    },
  },
})

export const {
  addNewAttendanceSubtype,
  addNewAttendanceType,
  closeAttendanceTypesDialog,
  confirmAttendanceSubtypeDeletion,
  confirmAttendanceTypeDeletion,
  editAttendanceSubtypes,
  editAttendanceType,
  setAttendanceType,
  setAttendanceSubtypes,
} = slice.actions

export default slice.reducer
