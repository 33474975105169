import { createAsyncThunk } from '@reduxjs/toolkit'

import santeApi from 'src/services/sante-api'

import { checkRequest } from 'src/features/helpers'

export const fetchHighlights = createAsyncThunk(
  'highlights/fetchHighlights',
  async () => {
    const res = await santeApi.get('admin/highlights')
    return res.data
  },
  {
    condition: ({ forceFetch = false } = {}, { getState }) => {
      return checkRequest(getState().highlights, 'fetchStatus', 'fulfilled') ? forceFetch : true
    },
  },
)

export const storeHighlight = createAsyncThunk('highlights/storeHighlight', async (data) => {
  const res = await santeApi.post('admin/highlights', data, {
    headers: { 'content-Type': 'multipart/form-data' },
  })
  return res.data
})

export const updateHighlight = createAsyncThunk(
  'highlights/updateHighlight',
  async ({ id, data }) => {
    const res = await santeApi.post(`admin/highlights/${id}`, data, {
      headers: { 'content-Type': 'multipart/form-data' },
    })
    return res.data
  },
)

export const destroyHighlight = createAsyncThunk('highlights/destroyHighlight', async (id) => {
  await santeApi.delete(`admin/highlights/${id}`)
  return { id }
})

export const toggleHighlightStatus = createAsyncThunk(
  'highlights/toggleHighlightStatus',
  async (id) => {
    const res = await santeApi.patch(`admin/highlights/${id}/toggle`)
    return res.data
  },
)
