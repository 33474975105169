import { useQueryClient } from '@tanstack/react-query'
import { createContext, useCallback, useEffect, useRef } from 'react'

import { FETCH_SPECIALTIES_QUERY_KEY } from 'src/features/specialties'
import { getQueryFromSessionStorage } from 'src/features/professionals'
import { FETCH_AVAILABLE_TIMES_QUERY_KEY } from 'src/features/attendance-schedules'

import { FETCH_NEEDED_DATA_QUERY_KEY } from 'src/features/appointments/useFetchNeededDataQuery'

const initialContext = {
  /**
   * @type {import('react').MutableRefObject<BroadcastChannel>}
   */
  broadcastRef: null,
  /**
   *
   * @param {Object} params
   * @param {String} params.event
   * @param {*} params.payload
   */
  dispatchBroadcastEvent: ({ event, payload }) => {
    console.log({ event, payload })
  },
}

export const BroadcastContext = createContext(initialContext)

export const BroadcastProvider = ({ children }) => {
  const broadcastRef = useRef(
    typeof window !== 'undefined' && 'BroadcastChannel' in window
      ? new BroadcastChannel('santeGlobalChannel')
      : null,
  )

  const queryClient = useQueryClient()

  const dispatchBroadcastEvent = useCallback(({ event, payload }) => {
    broadcastRef?.current.postMessage({
      event,
      payload,
      url: window.location.href,
    })
  }, [])

  const globalHandleMessage = useCallback(
    ({ data: { event, payload } }) => {
      const events = {
        refreshProfessionals: () => {
          queryClient.invalidateQueries({ queryKey: [getQueryFromSessionStorage()] })
        },
        refreshAvailableTimes: () => {
          queryClient.invalidateQueries({ queryKey: [FETCH_AVAILABLE_TIMES_QUERY_KEY] })
        },
        refreshSpecialties: () => {
          queryClient.invalidateQueries({ queryKey: [FETCH_SPECIALTIES_QUERY_KEY] })
          queryClient.invalidateQueries({ queryKey: [FETCH_NEEDED_DATA_QUERY_KEY] })
        },
      }

      if (event in events && typeof events[event] === 'function') {
        events[event](payload)
      }
    },
    [queryClient],
  )

  function globalHandleError(error) {
    console.error('Broadcast error', error)
  }

  useEffect(() => {
    const broadcast = broadcastRef.current

    broadcast.addEventListener('message', globalHandleMessage)

    broadcast.addEventListener('messageerror', globalHandleError)

    return () => {
      broadcast.removeEventListener('message', globalHandleMessage)

      broadcast.removeEventListener('messageerror', globalHandleError)
    }
  }, [globalHandleMessage])

  return (
    <BroadcastContext.Provider value={{ broadcastRef, dispatchBroadcastEvent }}>
      {children}
    </BroadcastContext.Provider>
  )
}
