import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  specialty: null,

  dialogState: { action: null, isOpen: false },
}

export const slice = createSlice({
  name: 'specialtyPrices',
  initialState,
  reducers: {
    showPricesOfClinics: (state, action) => {
      state.specialty = action.payload
      state.dialogState = { action: 'show-of-clinics', isOpen: true }
    },
    showPricesOfProfessionals: (state, action) => {
      state.specialty = action.payload
      state.dialogState = { action: 'show-of-professionals', isOpen: true }
    },
    resetDialogState: (state) => {
      state.specialty = initialState.specialty
      state.dialogState = initialState.dialogState
    },
  },
})

export const { resetDialogState, showPricesOfClinics, showPricesOfProfessionals } = slice.actions

export default slice.reducer
